import { AcGameObject } from './AcGameObject';
import { Cell } from './Cell';
import store from '@/store';

export class Snake extends AcGameObject {
    constructor(info, gamemap) {
        super();

        this.id = info.id;
        this.color = info.color;
        this.gamemap = gamemap;

        this.cells = [new Cell(info.r, info.c)];
        this.next_cell = null; // 下一步的目标位置

        this.speed = 5; // 每秒走5个格子
        this.direction = -1; // -1表示没有指令，0 1 2 3表示上右下左
        this.status = "idle"; // idle 静止， move 正在移动， die 表示死亡

        this.dr = [-1, 0, 1, 0];
        this.dc = [0, 1, 0, -1];

        this.step = 0;
        this.eps = 1e-2;

        this.eye_direction = 0;
        if (this.id === 1) this.eye_direction = 2;

        this.eye_dx = [ // 蛇眼的偏移量
            [-1, 1],
            [1, 1],
            [1, -1],
            [-1, -1],
        ];
        this.eye_dy = [
            [-1, -1],
            [-1, 1],
            [1, 1],
            [1, -1],
        ]
    }

    start() {
        if (store.state.pk.gamemap == null) {
            // 如果地图都没有，可以不用开始这局游戏了: 用户看录像后刷新浏览器
            this.destroy();
        }
    }

    set_direction(d) {
        this.direction = d;
    }

    check_tail_increasing() { // 检测当前回合蛇的长度是否变长
        if (this.step <= 10) return true;
        if (this.step % 3 === 1) return true;
        return false;
    }

    next_step() { // 将蛇的状态变为走下一步
        const d = this.direction;
        this.next_cell = new Cell(this.cells[0].r + this.dr[d], this.cells[0].c + this.dc[d]); // 目标位置
        this.eye_direction = d;
        this.direction = -1;
        this.status = "move";
        this.step++;

        // 先抛出一个新球
        const k = this.cells.length;
        for (let i = k; i > 0; i--) {
            //this.cells[i] = this.cells[i - 1]; 不能这样子写，这样写js中赋值赋的引用

            this.cells[i] = JSON.parse(JSON.stringify(this.cells[i - 1])); // 所以用深度复制一遍用jSON
            // 这样复制相当于数组中所有的元素都向后移动一个，且cells[0]是被复制出来的，然后将这个复制出来的cells[0]向next_cell移动
        }
    }

    update_move() {
        const dx = this.next_cell.x - this.cells[0].x;
        const dy = this.next_cell.y - this.cells[0].y;
        const distance = Math.sqrt(dx * dx + dy * dy); // 当前cells[0]距离next_cell还将要走的距离

        if (distance < this.eps) { // 走到目标点了

            this.cells[0] = this.next_cell; // 将目标点掉作为新的头部
            this.next_cell = null;

            if (!this.check_tail_increasing()) {
                this.cells.pop(); // 如果不变长，蛇尾砍掉
            }
            this.status = "idle";


        } else { // 如果不重合就移动
            const move_distance = this.speed * this.timedelta / 1000; // 每两帧之间移动的距离
            this.cells[0].x += move_distance * dx / distance;
            this.cells[0].y += move_distance * dy / distance; // 这样写可以适用于斜线移动，虽然这里用不到

            if (!this.check_tail_increasing()) { // 如果蛇尾不变长，蛇尾也要动
                const k = this.cells.length;
                const tail = this.cells[k - 1], tail_target = this.cells[k - 2];
                const tail_dx = tail_target.x - tail.x;
                const tail_dy = tail_target.y - tail.y;
                tail.x += move_distance * tail_dx / distance;
                tail.y += move_distance * tail_dy / distance;
            }
        }
    }

    update() {
        if (this.status === 'move') {
            this.update_move();
        }
        this.render();
    }

    render() {
        const L = this.gamemap.L;
        const ctx = this.gamemap.ctx;

        ctx.fillStyle = this.color;
        if (this.status === "die") ctx.fillStyle = "white";
        for (const cell of this.cells) { // 画身体
            ctx.beginPath();
            ctx.arc(cell.x * L, cell.y * L, L / 2 * 0.8, 0, Math.PI * 2); // 圆心坐标，半径，圆弧角度
            ctx.fill();
        }

        for (let i = 1; i < this.cells.length; i++) { // 相邻两个细胞之间补上矩形
            const a = this.cells[i - 1], b = this.cells[i];
            if (Math.abs(a.x - b.x) < this.eps && Math.abs(a.y - b.y) < this.eps) // 重合
                continue;

            if (Math.abs(a.x - b.x) < this.eps) { // 竖方向
                ctx.fillRect((a.x - 0.4) * L, Math.min(a.y, b.y) * L, L * 0.8, Math.abs(a.y - b.y) * L);
            } else { // 横方向
                ctx.fillRect(Math.min(a.x, b.x) * L, (a.y - 0.4) * L, Math.abs(a.x - b.x) * L, L * 0.8);
            }
        }

        //画眼镜
        ctx.fillStyle = "black";
        for (let i = 0; i < 2; i++) { // i枚举的是左眼右眼
            const eye_x = (this.cells[0].x + this.eye_dx[this.eye_direction][i] * 0.2) * L;
            const eye_y = (this.cells[0].y + this.eye_dy[this.eye_direction][i] * 0.2) * L;
            ctx.beginPath();
            ctx.arc(eye_x, eye_y, L * 0.06, 0, Math.PI * 2);
            ctx.fill();
        }
    }
}