import $ from 'jquery';
import $constant from "../utils/constant"
import $common from "../utils/common"

export default {
    state: {
        id: "",
        username: "",
        photo: "",
        nickname: "",
        token: "",
        is_login: false,
        is_admin: false,
        pulling_info: true, //表示是否正在拉取用户信息
        chatSocket: null,
        backList: [],
        backIcon: "https://hrf-w.oss-cn-chengdu.aliyuncs.com/bots-rider/user/backgroundImages/18.jpg",
    },

    getters: {
    },

    mutations: {
        updateUser(state, user) {
            state.id = user.id;
            state.username = user.username;
            state.photo = user.photo;
            state.nickname = user.nickname;
            state.is_login = user.is_login;
            state.is_admin = user.admin === "true" ? true : false;
            state.backIcon = user.backIcon == null ? state.backIcon : user.backIcon;
        },

        updateToken(state, token) {
            state.token = token;
        },

        logout(state) {
            state.id = "";
            state.username = "";
            state.photo = "";
            state.token = "";
            state.is_login = false;
        },

        updatePullingInfo(state, pulling_info) {
            state.pulling_info = pulling_info;
        },

        updateChatSocket(state, chatSocket) {
            state.chatSocket = chatSocket;
        },

        updateBackIcon(state, backIcon) {
            state.backIcon = backIcon;
        },

        clearSocket(state) {
            state.chatSocket = null;
        },

        updateBackList(state, list) {
            state.backList = list;
        },
    },

    actions: {
        login(context, data) { // 登录
            $.ajax({
                url: $constant.baseUrl + "/user/account/token/",
                type: "post",
                data: {
                    username: data.username,
                    password: data.password,
                },
                success(resp) {
                    if (resp.error_message === 'success') {
                        localStorage.setItem("jwt_token", resp.token);
                        context.commit("updateToken", resp.token);
                        data.success(resp);
                    } else {
                        data.error("用户名或密码错误, 登录失败!");
                    }
                },
                error(jqXHR) {
                    if (jqXHR.status) {
                        if (!navigator.onLine) {
                            data.error("网络未连接，请检查网络设置。");
                        } else if (jqXHR.status === 403) {
                            data.error("用户名或密码错误, 登录失败!");
                        } else {
                            data.error("服务器繁忙，请稍后再试。");
                        }
                    } else {
                        data.error("登录服务已停止~");
                    }
                }
            });
        },

        getInfo(context, data) { // 登录成功后获取信息
            $.ajax({
                url: $constant.baseUrl + "/user/account/info/",
                type: "get",
                headers: {
                    Authorization: "Bearer " + context.state.token,
                },
                success(resp) {
                    if (resp.error_message === 'success') {
                        context.commit("updateUser", {
                            ...resp,
                            is_login: true,
                        });
                        // 预处理背景图
                        localStorage.getItem("backList") == null ? $common.getBackgrounds() : context.commit("updateBackList", JSON.parse(localStorage.getItem("backList")));
                        data.success(resp);
                    } else {
                        data.error();
                    }

                    // 创建chatWebSocket
                    context.dispatch("createChatWebSocket");
                },
                error(jqXHR) {
                    $common.failedRequest(jqXHR)
                }
            })
        },

        // 退出登录
        logout(context) {
            context.commit("logout");
            context.commit("closeAdmin");
            if (context.state.chatSocket) {
                context.state.chatSocket.close();
            }
            context.commit("clearSocket")
            localStorage.removeItem("error_cnt");
            localStorage.removeItem("backList");
            localStorage.removeItem("jwt_token");
        },

        // 建立chatWebsocket连接和心脏跳动
        createChatWebSocket(context) {
            if (context.state.token !== "") {
                const socketUrl = $constant.socketUrl + context.state.token;
                let chatSocket = new WebSocket(socketUrl);
                context.commit("updateChatSocket", chatSocket);

                let intervalId;
                chatSocket.onopen = () => {
                    intervalId = setInterval(() => {
                        if (chatSocket && chatSocket.readyState === WebSocket.OPEN) {
                            // console.log("心脏跳动ok!");
                            chatSocket.send(JSON.stringify({
                                event: "heartbeat",
                                user_id: context.state.id
                            }));
                        } else {
                            // console.log("心脏跳动false!");
                            context.commit("clearSocket");
                            clearInterval(intervalId);
                        }
                    }, 1000000);
                }

                chatSocket.onclose = () => {
                    clearInterval(intervalId);
                    // 未来在这里可以写断线重连
                    // console.log("chat socket已经在store里断线了");
                }

            }
        },
    },

    modules: {
    }
}