import "core-js/modules/es.array.push.js";
import $ from "jquery";
import router from "@/router";
import { useStore } from "vuex";
import PostView from "@/components/profile/PostView.vue";
import { ref, onMounted, onUnmounted, nextTick, getCurrentInstance } from "vue";
export default {
  components: {
    PostView
  },
  setup() {
    const $constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    const store = useStore();
    const main = ref(null);
    const navbar = ref(null);
    const navbarBody = ref(null);
    let titleIndex = ref(0);
    let f1 = false;
    let h = ref([]);
    let posts = ref([]);
    let titles = ref([]);
    const showTitle = () => {
      if (window.innerWidth > 1023) {
        // 大屏幕
        if (navbar.value.style.display === 'block') {
          navbar.value.style.display = 'none';
          main.value.style.width = '80%';
          main.value.style.marginLeft = '10%';
        } else {
          navbar.value.style.display = 'block';
          main.value.style.width = '67%';
          main.value.style.marginLeft = '3%';
        }
        return;
      }
      // 小屏幕
      if (navbar.value.style.display === 'block') navbar.value.style.display = 'none';else navbar.value.style.display = 'block';
    };
    const checkWidth = () => {
      if (window.innerWidth > 1023) {
        navbar.value.style.display = 'block';
        main.value.style.width = '67%';
        main.value.style.marginLeft = '6%';
      } else if (window.innerWidth > 767 && window.innerWidth < 1024) {
        navbar.value.style.display = 'none';
        main.value.style.width = '85%';
        main.value.style.marginLeft = '7.5%';
      } else {
        navbar.value.style.display = 'none';
        main.value.style.width = '95%';
        main.value.style.marginLeft = '2.5%';
      }
    };
    onMounted(() => {
      window.addEventListener('resize', checkWidth);
      checkWidth();
    });
    onUnmounted(() => {
      window.removeEventListener('resize', checkWidth);
    });
    const resizeTitle = id => {
      // 调整title在视觉中央
      nextTick().then(() => {
        const currentTitle = navbarBody.value.children[id];
        if (currentTitle) {
          const h1 = currentTitle.offsetHeight / 2; // 当前标题高度的一半
          const h2 = navbarBody.value.offsetHeight; // navbarBody的高度
          const h3 = currentTitle.offsetTop + h1; // 当前标题的中心点到navbarBody的顶部距离
          let dir = h3 - h2 / 2; // 需要滚动的距离

          // 处理边界情况
          dir = Math.max(0, dir);
          dir = Math.min(dir, navbarBody.value.scrollHeight - h2);
          navbarBody.value.scrollTop = dir; // 滚动navbarBody到计算出的位置
          titleIndex.value = id; // 最后再把聚焦效果给上
        }
      });
    };

    const changeTitle = id => {
      f1 = true;
      main.value.scrollTop = h.value[id - 1] || 0; // 滚动左边内容
      setTimeout(() => {
        resizeTitle(id);
        f1 = false; // 每次点击标题，给文章200秒钟的滚动时间
      }, 200);
    };
    const scrollPost = () => {
      if (f1) return; // 点击标题导致的滚动
      let H = parseInt(main.value.scrollTop) + 300; // 当前的滚轮高度需要加点偏移量

      if (H < h.value[0]) titleIndex.value = 0;else {
        for (let i = 1; i < titles.value.length; i++) {
          if (H >= h.value[i - 1] && H < h.value[i]) {
            titleIndex.value = i;
            resizeTitle(i);
            break;
          }
        }
      }
    };
    const goAdd = () => {
      router.push({
        name: "user_profile_add_post"
      });
    };
    const getDefaultPost = () => {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      let time = `${year}-${month}-${day} ${hours}:${minutes}`;
      const post = {
        id: -1,
        title: "温馨提示",
        content: "你还没有发布帖子喔~",
        createtime: time
      };
      navbar.value.style.height = '61px';
      return post;
    };
    const getList = () => {
      $.ajax({
        url: $constant.baseUrl + "/user/profile/getAllPosts/",
        type: "GET",
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        success: async resp => {
          if (resp.error_message === "success") {
            titles.value = resp.titles;
            posts.value = resp.posts;
            await nextTick(); // 确保DOM更新已完成

            // 动态计算标题高度
            if (posts.value.length == 0) {
              posts.value.push(getDefaultPost());
            } else if (posts.value.length < 13) {
              let sum = 0;
              let children = navbar.value.children;
              for (let i = 0; i < children.length; i++) {
                let style = window.getComputedStyle(children[i]);
                sum += children[i].offsetHeight + parseInt(style.marginTop) + parseInt(style.marginBottom);
              }
              navbar.value.style.height = sum + 20 + 'px';
            }
            if (main.value == null) return;
            for (let i = 0; i < main.value.children.length; i++) {
              if (i === 0) h.value[0] = parseInt(main.value.children[0].clientHeight + 10);else h.value[i] = parseInt(h.value[i - 1] + main.value.children[i].clientHeight + 10);
            }
          }
        },
        error(jqXHR) {
          $common.failedRequest(jqXHR);
        }
      });
    };
    getList();
    return {
      main,
      posts,
      titles,
      navbar,
      titleIndex,
      navbarBody,
      goAdd,
      getList,
      showTitle,
      scrollPost,
      changeTitle
    };
  }
};