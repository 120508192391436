import "core-js/modules/es.array.push.js";
import $ from "jquery";
import Prism from "prismjs";
import router from '@/router';
import { useStore } from 'vuex';
import mark from "markdown-it-mark";
import MarkdownIt from "markdown-it";
import "prismjs/themes/prism-coy.css";
import { ref, computed, getCurrentInstance } from 'vue';
import SwitchView from "../utils/SwitchView.vue";
export default {
  components: {
    SwitchView
  },
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const $constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    const store = useStore();
    let post = ref(props.post);
    let createtime = $common.getDateDiff(post.value.createtime);
    const md = new MarkdownIt({
      html: true,
      breaks: true,
      linkify: true,
      typographer: true,
      highlight: function (str, lang) {
        if (lang && Prism.languages[lang]) {
          try {
            return Prism.highlight(str, Prism.languages[lang], lang);
          } catch (err) {
            console.error(`代码高亮错误: ${err}`);
          }
        }
        return "";
      }
    }).use(mark);
    md.use(function (md) {
      const defaultRender = md.renderer.rules.image;
      let imgCnt = 0; // 初始化计数器
      md.renderer.rules.image = function (tokens, idx, options, env, self) {
        var result = defaultRender(tokens, idx, options, env, self);
        // 检查当前图片是否是第一个
        let firstStyle = '';
        if (imgCnt === 0) {
          firstStyle = 'margin-top: -60px; border-top: 1px dotted grey;';
        }
        imgCnt++;
        return `
                    <div class="responsive-image-container" style="${firstStyle} position: relative; width: 100%;padding-bottom: 35%; overflow: hidden;border-bottom: 1px dotted grey;">
                        <img class="responsive-image" style="position: absolute; top: 0; left: 0; width: 100%; height: 95%; object-fit: contain;" ${result.split("<img ")[1]}
                    </div>
                `;
      };
    });
    let compiledContent = computed(() => {
      // 将markdown文件编译成html
      return md.render(post.value.content);
    });
    const goUpdate = () => {
      if (post.value.id < 0) {
        return;
      }
      store.commit("updatePost", post.value);
      router.push({
        name: "user_profile_update_post"
      });
    };
    const deletePost = () => {
      $common.confirmAction("警告", "你确定删除此文章?", "red").then(result => {
        if (result) {
          if (post.value.id === -1) {
            $common.Error("post不存在，无法删除此post", 1000);
            return;
          }
          $.ajax({
            url: $constant.baseUrl + "/user/profile/delete/",
            type: "POST",
            data: {
              post_id: post.value.id
            },
            headers: {
              Authorization: "Bearer " + store.state.user.token
            },
            success(resp) {
              if (resp.error_message === "success") {
                $common.Success("删除成功", 1000);
                setTimeout(() => {
                  window.location.reload(); // 这里采用刷新浏览器更新列表
                }, 1000);
              } else {
                $common.Error(resp.error_message);
              }
            },
            error(jqXHR) {
              $common.failedRequest(jqXHR);
            }
          });
        }
      });
    };
    const checked = ref(false);
    if (post.value.permission === "private") {
      checked.value = true;
    }
    const onChange = checked => {
      let permission = "public";
      if (checked) {
        permission = "private";
      }
      $.ajax({
        url: $constant.baseUrl + "/user/profile/updatePermission/",
        type: "post",
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        data: {
          post_id: post.value.id,
          permission: permission
        },
        success(resp) {
          if (resp.error_message !== "success") {
            $common.Error(resp.error_message);
          }
        },
        error(jqXHR) {
          $common.failedRequest(jqXHR);
        }
      });
    };
    return {
      checked,
      createtime,
      compiledContent,
      onChange,
      goUpdate,
      deletePost
    };
  }
};