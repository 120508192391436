import { createRouter, createWebHistory } from 'vue-router'
import NotFoundView from '../views/error/NotFoundView';
import PkIndexView from '../views/pk/PkIndexView';
import RanklistIndexView from '../views/ranklist/RanklistIndexView';
import RecordIndexView from '../views/record/RecordIndexView';
import RecordContentView from '../views/record/RecordContentView';
import UserBotIndexView from '../views/user/bot/UserBotIndexView';
import UserProfileIndexView from '../views/profile/UserProfileIndexView';
import AddPost from '../components/profile/AddPost';
import UpdatePost from '../components/profile/UpdatePost';
import UserInfoView from '../views/user/info/UserInfoView';
import UserAccountLoginView from '../views/user/account/UserAccountLoginView';
import EmailLogin from "../components/user/EmailLogin";
import UserAccountRegisterView from '../views/user/account/UserAccountRegisterView';
import HomeView from '../components/utils/HomeView';
import FriendsIndexView from '../views/friends/FriendsIndexView';
import HomeIndexView from '../views/home/HomeIndexView';
import TestView from "../views/test/TestView";
import UserSetView from "../views/user/set/UserSetView";
import WelCome from "../views/admin/welcome/WelCome";
import store from '../store/index'
import $common from "../utils/common"

const routes = [
  {
    path: "/",
    name: "home_",
    redirect: "/square",
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/square",
    name: "home_index",
    component: HomeIndexView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/hello",
    name: "home",
    component: HomeView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/pk",
    name: "pk_index",
    component: PkIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/ranklist",
    name: "ranklist_index",
    component: RanklistIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/record",
    name: "record_index",
    component: RecordIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/record",
    name: "record_content",
    component: RecordContentView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/user/bot",
    name: "user_bot_index",
    component: UserBotIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/user/info",
    name: "user_info_index",
    component: UserInfoView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/user/set",
    name: "user_set_index",
    component: UserSetView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/user/profile",
    name: "user_profile_index",
    component: UserProfileIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/user/profile/addPost",
    name: "user_profile_add_post",
    component: AddPost,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/user/profile/updatePost",
    name: "user_profile_update_post",
    component: UpdatePost,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/friends",
    name: "friends_index",
    component: FriendsIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/404",
    name: "404",
    component: NotFoundView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/user/account/register",
    name: "user_account_register",
    component: UserAccountRegisterView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/user/account/login",
    name: "user_account_login",
    component: UserAccountLoginView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/user/account/login/email",
    name: "user_account_login_email",
    component: EmailLogin,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/user/account/qq/receive_code",
    name: "user_account_qq_receive_code",
    component: () => import('@/components/user/QQLogin'),
    meta: {
      requestAuth: false
    }
  },
  {
    path: "/test",
    name: "test",
    component: TestView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404/"
  },
  {
    path: "/admin",
    name: "admin",
    component: WelCome,
    meta: {
      requestAuth: true,
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => { // 在router起作用前执行的函数
  if (!store.state.user.is_login && localStorage.getItem("jwt_token") != null) { // 1.浏览器有token被刷新掉了 -> 还能登录挽救
    const jwt_token = localStorage.getItem("jwt_token");
    store.commit("updateToken", jwt_token);
    store.dispatch("getInfo", {
      success() {
        next({
          name: to.name,
          params: to.params,
        });
      },
      error() {

      }
    });
  } else if (!store.state.user.is_login && to.meta.requestAuth) { // 没登陆却要访问授权页面 -> 无法挽救
    $common.Error("请先登录~", 1000);
    router.push({
      name: "user_account_login"
    })
  } else if (store.state.user.is_login && to.name === "admin") { // 登录了需要访问管理界面 -> 判断
    if (store.state.user.is_admin) {
      next();
    } else {
      next({
        name: "404"
      });
    }
  }
  else { // 登陆了或者访问不需要授权的页面
    next();
  }
})

export default router
