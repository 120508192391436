export default {
    state: {
        status: "matching", // matching表示匹配界面，playing表示对战界面
        socket: null,
        opponent_username: "", // 存下对手用户名
        opponent_nickname: "", // 对手昵称
        opponent_photo: "",
        gamemap: null,
        a_id: 0,
        a_sx: 0,
        a_sy: 0,
        b_id: 0,
        b_sx: 0,
        b_sy: 0,
        gameObjext: null,
        loser: "none",// none、all、A、B  
        redNickName: "",
        blueNickName: "",
    },

    getters: {
    },

    mutations: {
        updateSocket(state, socket) {
            state.socket = socket;
        },
        updateOpponent(state, opponent) {
            state.opponent_username = opponent.opponent_username;
            state.opponent_photo = opponent.opponent_photo;
            state.opponent_nickname = opponent.opponent_nickname;
        },
        updateStatus(state, status) {
            state.status = status;
        },
        updateGame(state, game) {
            state.gamemap = game.gamemap;
            state.a_id = game.a_id;
            state.a_sx = game.a_sx;
            state.a_sy = game.a_sy;
            state.b_id = game.b_id;
            state.b_sx = game.b_sx;
            state.b_sy = game.b_sy;
            state.redNickName = game.redNickName;
            state.blueNickName = game.blueNickName;
        },
        updateGameObject(state, gameObject) {
            state.gameObject = gameObject;
        },
        updateLoser(state, loser) {
            state.loser = loser;
        }
    },

    actions: {

    },

    modules: {
    }
}