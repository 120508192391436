import "core-js/modules/es.array.push.js";
import $ from 'jquery';
import { useStore } from 'vuex';
import { ref, getCurrentInstance } from 'vue';
export default {
  name: "RanklistIndexView",
  setup() {
    const $constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    const store = useStore();
    let users = ref([]); // 当前获取到的users
    let current_page = ref(1);
    let total_users = 0;
    let pages = ref([]); // 一共多少个分页角标

    const click_page = page => {
      if (page === -2) page = current_page.value - 1;else if (page === -1) page = current_page.value + 1;
      let max_pages = parseInt(Math.ceil(total_users / 9));
      if (page >= 1 && page <= max_pages) {
        pull_page(page);
      }
    };
    const update_pages = () => {
      let page_count = parseInt(Math.ceil(total_users / 9));
      let t = [];
      let l, r;
      if (current_page.value > 2 && current_page.value < page_count - 1) {
        l = current_page.value - 2;
        r = current_page.value + 2;
      } else if (current_page.value <= 2) {
        l = 1;
        r = 5;
      } else if (current_page.value >= page_count - 1) {
        r = page_count;
        l = page_count - 4;
      }
      for (let i = Math.max(1, l); i <= Math.min(r, page_count); i++) {
        t.push({
          number: i,
          is_active: i === current_page.value ? "active" : ""
        });
      }
      pages.value = t;
    };
    const pull_page = page => {
      current_page.value = page;
      $.ajax({
        url: $constant.baseUrl + "/rankList/page",
        type: "GET",
        data: {
          "pageNo": page,
          "pageSize": 9
        },
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        success(resp) {
          if (resp.error_message === "success") {
            users.value = resp.list;
            total_users = resp.total;
            update_pages();
          }
        },
        error(jqXHR) {
          $common.failedRequest(jqXHR);
        }
      });
    };
    pull_page(current_page.value);
    return {
      users,
      pages,
      current_page,
      click_page
    };
  }
};