import { useStore } from 'vuex';
import { ref, getCurrentInstance, onMounted } from 'vue';
import $ from 'jquery';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import common from '@/utils/common';
export default {
  setup() {
    const $constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    const store = useStore();
    let nickname = ref("");
    let email = ref("");
    let phone = ref("");
    let sex = ref("man");
    let age = ref(18);
    let brief = ref("");
    let photo = ref(store.state.user.photo);
    let oldPassword = ref("");
    let password = ref("");
    let confirmedPassword = ref("");
    const updateInfo = () => {
      if ($common.isEmpty(nickname.value) || $common.isEmpty(email.value) || $common.isEmpty(phone.value) || $common.isEmpty(sex.value) || $common.isEmpty(age.value) || $common.isEmpty(brief.value)) {
        $common.Error("参数不能为空!");
        return;
      } else {
        $.ajax({
          url: $constant.baseUrl + "/user/info/update/",
          type: "post",
          headers: {
            Authorization: "Bearer " + store.state.user.token
          },
          data: {
            nickname: nickname.value,
            email: email.value,
            phone: phone.value,
            sex: sex.value,
            age: age.value,
            brief: brief.value
          },
          success(resp) {
            if (resp.error_message === "success") {
              $common.Success("更新用户信息成功!");
            } else {
              $common.Error(resp.error_message);
            }
          },
          error(jqXHR) {
            $common.failedRequest(jqXHR);
          }
        });
      }
    };
    const getInfo = () => {
      $.ajax({
        url: $constant.baseUrl + "/user/info/get/",
        type: "get",
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        success(resp) {
          if (resp.error_message === "success") {
            nickname.value = resp.user.nickname;
            email.value = resp.user.email;
            phone.value = resp.user.phone;
            sex.value = resp.user.sex;
            age.value = resp.user.age;
            brief.value = resp.user.brief;
          } else {
            $common.Error(resp.error_message);
          }
        },
        error(jqXHR) {
          $common.failedRequest(jqXHR);
        }
      });
    };
    getInfo();
    const modal = ref(null);
    const modal2 = ref(null);
    onMounted(() => {
      modal.value = new Modal(document.getElementById('photoModal'));
      modal2.value = new Modal(document.getElementById('passwordModal'));
      // 监听模态框隐藏事件来清空图片
      document.getElementById('photoModal').addEventListener('hidden.bs.modal', clearImage);
    });
    const clearImage = () => {
      imagePreview.value = ''; // 清空预览
      fileName.value = ''; // 清空文件名
      const fileInput = document.querySelector('.upload-btn');
      if (fileInput) {
        fileInput.value = ''; // 重置文件输入，允许再次选择相同的文件
      }
    };

    const showModal = () => {
      modal.value.show();
    };
    let imagePreview = ref('');
    const fileName = ref('');
    let policy = ref({});
    let file = ref(null);
    const triggerInput = () => {
      const fileInput = document.querySelector('.upload-btn');
      if (fileInput) {
        fileInput.click();
      }
    };

    // 展示预览并获取上传授权
    const previewImage = event => {
      file.value = event.target.files[0];
      fileName.value = file.value ? file.value.name : '';
      if (file.value) {
        const reader = new FileReader();
        reader.onload = e => {
          imagePreview.value = e.target.result;
        };
        reader.readAsDataURL(file.value);
        // 获取后端授权
        $.ajax({
          url: $constant.baseUrl + "/user/info/policy/",
          type: "GET",
          headers: {
            Authorization: "Bearer " + store.state.user.token
          },
          data: {
            path: "/user/photo/"
          },
          success(resp) {
            // 成功获取后端授权
            //console.log(resp);
            policy.value.key = `${resp.dir}/${Date.now()}-${file.value.name}`;
            policy.value.OSSAccessKeyId = resp.accessid;
            policy.value.policy = resp.policy;
            policy.value.signature = resp.signature;
            policy.value.file = file.value;
            policy.value.host = resp.host;
            policy.value.f = true;
            //console.log(policy.value);
          },

          error(jqXHR) {
            $common.failedRequest(jqXHR);
          }
        });
      }
    };
    const upload = async () => {
      // 把头像存到oss服务器，并更新后端数据库
      //console.log(file.value);
      if (!file.value || !policy.value.f) {
        $common.Error("未选中任何图片!");
        return;
      }
      let formData = new FormData();
      formData.append('key', policy.value.key);
      formData.append('OSSAccessKeyId', policy.value.OSSAccessKeyId);
      formData.append('policy', policy.value.policy);
      formData.append('signature', policy.value.signature);
      formData.append('file', policy.value.file);
      $.ajax({
        url: policy.value.host,
        type: "POST",
        data: formData,
        processData: false,
        contentType: false,
        success() {
          //console.log("oss上传成功");
          photo.value = `${policy.value.host}/${formData.get('key')}`;
          //调用api更新用户头像
          $.ajax({
            url: $constant.baseUrl + "/user/info/updatePhoto",
            type: "POST",
            data: {
              photo: photo.value
            },
            headers: {
              Authorization: "Bearer " + store.state.user.token
            },
            success(resp) {
              if (resp.error_message === "success") {
                $common.Success("头像已更新成功~");
                setTimeout(() => {
                  modal.value.hide();
                }, 1500);
              }
            },
            error() {
              console.log("连接失败");
            }
          });
        },
        error(jqXHR) {
          $common.failedRequest(jqXHR);
        }
      });
    };
    const updatePassword = () => {
      $.ajax({
        url: $constant.baseUrl + "/user/info/updatePassword",
        type: "post",
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        data: {
          oldPassword: oldPassword.value,
          password: password.value,
          confirmedPassword: confirmedPassword.value
        },
        success(resp) {
          if (resp.error_message === "success") {
            common.Success("更新密码成功!", 1000);
            oldPassword.value = "";
            password.value = "";
            confirmedPassword.value = "";
            setTimeout(() => {
              modal2.value.hide();
            }, 1500);
          } else {
            common.Error(resp.error_message);
          }
        },
        error(jqXHR) {
          $common.failedRequest(jqXHR);
        }
      });
    };
    return {
      age,
      sex,
      store,
      email,
      brief,
      phone,
      nickname,
      showModal,
      fileName,
      imagePreview,
      triggerInput,
      previewImage,
      updateInfo,
      upload,
      photo,
      oldPassword,
      password,
      confirmedPassword,
      updatePassword
    };
  }
};