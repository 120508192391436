import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "fixed-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_NavBar = _resolveComponent("NavBar");
  const _component_router_view = _resolveComponent("router-view");
  const _component_FootView = _resolveComponent("FootView");
  return _openBlock(), _createElementBlock("div", {
    class: "body",
    style: _normalizeStyle('background-image: url(' + _ctx.$store.state.user.backIcon + ')')
  }, [!_ctx.$store.state.admin.f ? (_openBlock(), _createBlock(_component_NavBar, {
    key: 0
  })) : _createCommentVNode("", true), (_openBlock(), _createBlock(_component_router_view, {
    key: _ctx.$route.fullPath
  })), !_ctx.$store.state.admin.f ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_FootView)])) : _createCommentVNode("", true)], 4);
}