import { Modal } from 'bootstrap/dist/js/bootstrap';
import constant from './constant';
import router from "../router/index"
import store from '@/store';
import $ from "jquery";
export default {
    isEmpty(value) {
        return (
            typeof value === "undefined" ||
            value === null ||
            (typeof value === "string" && value.trim() === "") ||
            (Array.isArray(value) && value.length === 0) ||
            (Object.getPrototypeOf(value) === Object.prototype && Object.keys(value).length === 0)
        );
    },
    // 敏感操作的Modal
    confirmAction(title, body, color) {// 参数：提示信息，警告颜色
        const existingModal = document.getElementById('exampleModal');
        if (existingModal) {
            existingModal.remove();
        }

        const modalHTML = `
        <div style="font-family: SmileySans;" class="modal modal-sm fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="margin-top: 100px;">
                <div class="modal-content">
                <div class="modal-header" style="border-bottom: none; padding: 10px;">
                    <h6 class="modal-title" id="exampleModalLabel" style="display: flex; flex-direction: row;">
                        <div style="width: 25px; height: 25px; background-color: ${color}; 
                        border-radius: 50%; text-align: center; line-height: 25px; color: white;font-family: none;">X</div>
                        <div style="font-size: 16px; margin-left: 10px;font-weight:1000;">${title}</div>
                    </h6>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" style="font-size: 16px;"> 
                    ${body}
                </div>
                <div class="modal-footer" style="border-top: none;"> 
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">取消</button>
                    <button type="button" class="btn btn-sm btn-danger" id="saveChanges" style="background-color: ${color}; border: none;">确定</button>
                </div>
                </div>
            </div>
        </div>
        `;


        document.body.insertAdjacentHTML('beforeend', modalHTML);

        // 用Bootstrap的JavaScript API来显示模态框
        let myModal = new Modal(document.getElementById('exampleModal'), {
            keyboard: true
        });
        myModal.show();

        // 返回一个 Promise，它会在模态框关闭时解决
        return new Promise((resolve) => {
            // 监听保存按钮的点击事件
            document.getElementById('saveChanges').addEventListener('click', () => {
                myModal.hide();
                resolve(true); // 如果用户点击“确定”，则返回 true
            });

            // 监听模态框关闭的事件
            document.getElementById('exampleModal').addEventListener('hidden.bs.modal', (event) => {
                if (event.target === document.getElementById('exampleModal')) {
                    resolve(false); // 如果模态框以其他方式关闭，则返回 false
                }
            });
        });
    },
    // 错误提示
    Error(body, time) {
        const errorModal = document.getElementById('errorModal');
        if (errorModal) {
            errorModal.remove();
        }
        const modalHTML = `
        <div style="font-family: SmileySans;" class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static">
            <div class="modal-dialog modal-sm" style="margin-top: 100px;">
                <div class="modal-content">
                    <div class="modal-body" style="background-color:#F3424E;display:flex;flex-direction:row;align-items:center;border-radius:5px;">
                        <div style="width: 20px;height:20px;border-radius:50%;font-size:12px;
                        background-color:white;color:#F3424E;text-align:center;line-height:20px;font-family:none;">X</div>
                        <div style="color:white;font-weight:1000;margin-left:10px;line-height:20px;">${body}</div>
                    </div>
                </div>
            </div>
        </div>
        `;
        document.body.insertAdjacentHTML('beforeend', modalHTML);
        const myModal = new Modal(document.getElementById('errorModal'));
        myModal.show();
        setTimeout(() => {
            myModal.hide();
        }, time == null ? 1000 : time);
    },
    //成功提示
    Success(body, time) {
        const successModal = document.getElementById('successModal');
        if (successModal) {
            successModal.remove();
        }
        const modalHTML = `
        <div style="font-family: SmileySans;" class="modal fade" id="successModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static">
            <div class="modal-dialog modal-sm" style="margin-top: 100px;">
                <div class="modal-content">
                    <div class="modal-body" style="background-color:#0cc530;display:flex;flex-direction:row;align-items:center;border-radius:5px;">
                        <div style="width: 20px;height:20px;border-radius:50%;font-size:12px;
                        background-color:white;color:#0cc530;font-weight:1000;text-align:center;line-height:20px;font-family:none;">√</div>
                        <div style="color:white;font-weight:1000;margin-left:10px;line-height:20px;">${body}</div>
                    </div>
                </div>
            </div>
        </div>
        `;
        document.body.insertAdjacentHTML('beforeend', modalHTML);
        const myModal = new Modal(document.getElementById('successModal'));
        myModal.show();
        setTimeout(() => {
            myModal.hide();
        }, time == null ? 1000 : time);
    },
    // 请求失败
    failedRequest(jqXHR) {
        if (jqXHR.status) {
            if (!navigator.onLine) {
                this.Error("网络未连接，请检查网络设置。");
            } else if (jqXHR.status === 404) {
                this.Error("404请求地址错误!")
            } else {
                // 其他请求错误，例如500错误，允许试错1次
                if (localStorage.getItem("error_cnt") == null) {
                    localStorage.setItem("error_cnt", 1);
                    this.Error("服务器繁忙，请刷新浏览器后重试~");
                } else {
                    store.dispatch("logout");
                    this.Error("已清理本地缓存，请重新登录");
                    router.push({
                        name: "user_account_login"
                    })
                }
            }
        } else { // 403情况
            store.dispatch("logout");
            this.Error("登录状态已过期，请重新登录");
            router.push({
                name: "user_account_login"
            })
        }
    },
    // 获取网站背景图
    getBackgrounds() {
        $.ajax({
            url: constant.baseUrl + "/user/set/getBackgrounds/",
            type: "get",
            headers: {
                Authorization: "Bearer " + store.state.user.token,
            },
            success(resp) {
                if (resp.error_message === "success") {
                    store.commit("updateBackList", resp.list);
                    localStorage.setItem("backList", JSON.stringify(resp.list));
                }
            }
        })
    },
    // 获取时间差
    getDateDiff(dateStr) {
        let publishTime = Date.parse(dateStr.replace(/-/gi, "/")) / 1000,
            d_seconds,
            d_minutes,
            d_hours,
            d_days,
            timeNow = Math.floor(new Date().getTime() / 1000),
            d,
            date = new Date(publishTime * 1000),
            Y = date.getFullYear(),
            M = date.getMonth() + 1,
            D = date.getDate(),
            H = date.getHours(),
            m = date.getMinutes(),
            s = date.getSeconds();
        //小于10的在前面补0
        if (M < 10) {
            M = '0' + M;
        }
        if (D < 10) {
            D = '0' + D;
        }
        if (H < 10) {
            H = '0' + H;
        }
        if (m < 10) {
            m = '0' + m;
        }
        if (s < 10) {
            s = '0' + s;
        }
        d = timeNow - publishTime;
        d_days = Math.floor(d / 86400);
        d_hours = Math.floor(d / 3600);
        d_minutes = Math.floor(d / 60);
        d_seconds = Math.floor(d);
        if (d_days > 0 && d_days < 3) {
            return d_days + '天前';
        } else if (d_days <= 0 && d_hours > 0) {
            return d_hours + '小时前';
        } else if (d_hours <= 0 && d_minutes > 0) {
            return d_minutes + '分钟前';
        } else if (d_seconds < 60) {
            if (d_seconds <= 0) {
                return '刚刚';
            } else {
                return d_seconds + '秒前';
            }
        } else if (d_days >= 3 && d_days < 30) {
            return M + '-' + D + ' ' + H + ':' + m;
        } else if (d_days >= 30) {
            return Y + '-' + M + '-' + D + ' ' + H + ':' + m;
        }
    },
    // 是否小于5分钟
    isLowFive(t1, t2) {
        let t1Sec = Date.parse(t1.replace(/-/gi, "/")) / 1000;
        let t2Sec = Date.parse(t2.replace(/-/gi, "/")) / 1000;
        if (Math.abs(t1Sec - t2Sec) < 5 * 60) return true;
        else return false;
    }
}