import "core-js/modules/es.array.push.js";
import { ref, getCurrentInstance } from 'vue';
import $ from 'jquery';
import router from '../../../router/index';
export default {
  name: "RanklistIndexView",
  setup() {
    const $constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    let username = ref('');
    let password = ref('');
    let confirmedPassword = ref('');
    let parent = ref(null);
    let canvas = ref(null);
    let timer = ref('');
    let register_ok = ref(false); // 只有ref(reactive)可以动态修改前端

    const register = () => {
      if ($common.isEmpty(username.value)) {
        $common.Error("用户名不能为空!");
        return;
      }
      if ($common.isEmpty(password.value) || $common.isEmpty(confirmedPassword.value)) {
        $common.Error("密码不能为空!");
        return;
      }
      $.ajax({
        url: $constant.baseUrl + "/user/account/register/",
        type: "post",
        data: {
          username: username.value,
          password: password.value,
          confirmedPassword: confirmedPassword.value
        },
        success(resp) {
          if (resp.error_message === 'success') {
            register_ok.value = true;
            // 注册成功实现一个5秒后再跳转的效果
            let sec_ok = (new Date().getSeconds() + 5) % 60;
            const step = () => {
              let now = new Date().getSeconds();
              if (now === sec_ok) {
                router.push({
                  name: "user_account_login"
                }); // 再跳转
                register_ok.value = false;
                return;
              }
              timer.value = (sec_ok - now + 60) % 60;
              requestAnimationFrame(step);
            };
            step();
          } else {
            $common.Error(resp.error_message);
          }
        },
        error() {
          if (!navigator.onLine) {
            $common.Error("网络未连接，请检查网络设置。");
          } // 不存在403,这个接口开发的
          // 其他的AJAX请求错误，例如500错误
          else {
            $common.Error("服务器繁忙，请稍后再试。");
          }
        }
      });
    };
    const go = Name => {
      router.push({
        name: Name
      });
    };
    const qq_login = () => {
      $.ajax({
        url: "https://www.hrf-w.com/api/user/account/qq/apply_code/",
        type: "GET",
        success: resp => {
          if (resp.error_message === "success") {
            window.location.replace(resp.apply_code_url);
          }
        }
      });
    };
    return {
      username,
      password,
      confirmedPassword,
      register,
      parent,
      canvas,
      timer,
      register_ok,
      go,
      qq_login
    };
  }
};