import $ from 'jquery';
import { useStore } from 'vuex';
import { getCurrentInstance, ref } from 'vue';
export default {
  setup() {
    const store = useStore();
    const $constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    let admin = parseInt(store.state.user.id) === 1 ? true : false;
    let informs = ref([]);
    let content = ref('');
    const getSystemInform = () => {
      $.ajax({
        url: $constant.baseUrl + "/system/inform/get/",
        type: "get",
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        success(resp) {
          if (resp.error_message === "success") {
            informs.value = resp.informs;
            for (let i = 0; i < informs.value.length; i++) {
              informs.value[i].createtime = $common.getDateDiff(informs.value[i].createtime);
            }
          }
        },
        error() {
          console.log("get inform Error");
        }
      });
    };
    getSystemInform();
    const addInform = () => {
      if ($common.isEmpty(content.value)) {
        return;
      }
      $.ajax({
        url: $constant.baseUrl + "/system/inform/add/",
        type: "post",
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        data: {
          content: content.value
        },
        success(resp) {
          if (resp.error_message === "success") {
            getSystemInform();
          }
        },
        error() {
          console.log("add inform Error");
        }
      });
    };
    return {
      admin,
      informs,
      content,
      addInform
    };
  }
};