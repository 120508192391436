import { GameMap } from '@/assets/script/GameMap.js';
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
export default {
  setup() {
    const store = useStore();
    let parent = ref(null); // 注意这俩变量是绑定上面的标签
    let canvas = ref(null);
    onMounted(() => {
      // 组件挂载完之后执行
      store.commit("updateGameObject", new GameMap(canvas.value.getContext('2d'), parent.value, store));
    });
    return {
      parent,
      canvas
    };
  }
};