import { AcGameObject } from './AcGameObject';
import { Snake } from './Snake';
import { Wall } from './Wall';

export class GameMap extends AcGameObject {
    constructor(ctx, parent, store) {
        super();
        this.ctx = ctx;
        this.parent = parent;
        this.store = store;
        this.L = 0; // 这里是每个格子的绝对距离，未来的设置都是相对的这个距离
        this.rows = 13;
        this.cols = 14;
        this.walls = [];
        this.snakes = [
            new Snake({
                id: 0,
                color: "#F94847",
                r: this.rows - 2,
                c: 1,
            }, this),
            new Snake({
                id: 1,
                color: "#4876EB",
                r: 1,
                c: this.cols - 2,
            }, this),
        ];
    }

    create_walls() {
        // 先拿到地图再更新前端参数
        const g = this.store.state.pk.gamemap;
        for (let r = 0; r < this.rows; r++) {
            for (let c = 0; c < this.cols; c++) {
                if (g[r][c]) {
                    this.walls.push(new Wall(r, c, this));
                }
            }
        }
    }

    add_listening_events() {
        if (this.store.state.record.is_record) { // 如果是录像
            let k = 0;
            const a_steps = this.store.state.record.a_steps;
            const b_steps = this.store.state.record.b_steps;
            const [snake0, snake1] = this.snakes;
            const loser = this.store.state.record.record_loser;
            const interval_id = setInterval(() => {
                if (k >= a_steps.length - 1) { //两条蛇走的步数一样，但是最后一步有一条是非法
                    if (loser === "all" || loser === "A") {
                        snake0.status = "die";
                    }
                    if (loser === "all" || loser === "B") {
                        snake1.status = "die";
                    }
                    clearInterval(interval_id);
                } else {
                    snake0.set_direction(parseInt(a_steps[k]));
                    snake1.set_direction(parseInt(b_steps[k]));
                }
                k++;
            }, 300); // 每300ms执行一下

        } else { // 不是录像
            this.ctx.canvas.focus(); // 聚焦
            this.ctx.canvas.addEventListener("keydown", e => { // 绑定keydown事件
                let d = -1;
                if (e.key === 'w') d = 0;
                else if (e.key === 'd') d = 1;
                else if (e.key === 's') d = 2;
                else if (e.key === 'a') d = 3;

                if (d >= 0) { // 向后端传递信息d
                    this.store.state.pk.socket.send(JSON.stringify({
                        // 把一个json变成string
                        event: "move",
                        direction: d,
                    }));
                }
            });
        }
    }

    start() {
        if (this.store.state.pk.gamemap == null) {
            // 如果地图都没有，可以不用开始这局游戏了: 用户看录像后刷新浏览器
            this.destroy();
            return;
        }
        this.create_walls();
        this.add_listening_events();
    }

    check_valid(cell) { // 检测目标位置是否合法：没有撞到两条蛇的身体和墙
        for (const wall of this.walls) {
            if (wall.r === cell.r && wall.c === cell.c)
                return false;
        }
        for (const snake of this.snakes) {
            let k = snake.cells.length;
            if (!snake.check_tail_increasing()) {
                k--; // 蛇尾不变长，最后一个格子不判断
            }
            for (let i = 0; i < k; i++) {
                if (snake.cells[i].r === cell.r && snake.cells[i].c === cell.c)
                    return false;
            }
        }
        return true;
    }

    update_size() {
        this.L = parseInt(Math.min(this.parent.clientWidth / this.cols, this.parent.clientHeight / this.rows));
        this.ctx.canvas.width = this.L * this.cols;
        this.ctx.canvas.height = this.L * this.rows;
    }

    check_ready() { // 判断两条蛇是否都准备好进行下一回合
        for (const snake of this.snakes) {
            if (snake.status !== "idle") return false;
            if (snake.direction === -1) return false;
        }
        return true;
    }

    next_step() { // 让两条蛇都进入下一回合
        for (const snake of this.snakes) {
            snake.next_step();
        }
    }

    update() {
        this.update_size();
        if (this.check_ready()) {
            this.next_step();
        }
        this.render();
    }

    render() {
        const color_even = "#A2D048", color_odd = "#AAD751";
        for (let r = 0; r < this.rows; r++) {
            for (let c = 0; c < this.cols; c++) {
                if ((r + c) % 2 == 0) {
                    this.ctx.fillStyle = color_even;
                } else {
                    this.ctx.fillStyle = color_odd;
                }
                this.ctx.fillRect(c * this.L, r * this.L, this.L, this.L);
            }
        }
    }
}