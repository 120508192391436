import $ from "jquery";
import { useStore } from "vuex";
import { ref, reactive, getCurrentInstance } from 'vue';
import { VAceEditor } from 'vue3-ace-editor';
import "ace-builds/webpack-resolver";
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/ext-language_tools';
import { Modal } from 'bootstrap/dist/js/bootstrap';
export default {
  components: {
    VAceEditor
  },
  setup() {
    const store = useStore();
    const $constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    let bots = ref([]);
    let botAdd = reactive({
      title: "",
      description: "",
      content: ""
    });
    const aceConfig = reactive({
      theme: 'crimson_editor',
      //主题
      arr: [/*所有主题*/
      "ambiance", "chaos", "chrome", "clouds", "clouds_midnight", "cobalt", "crimson_editor", "dawn", "dracula", "dreamweaver", "eclipse", "github", "gob", "gruvbox", "idle_fingers", "iplastic", "katzenmilch", "kr_theme", "kuroir", "merbivore", "merbivore_soft", "monokai", "mono_industrial", "pastel_on_dark", "solarized_dark", "solarized_light", "sqlserver", "terminal", "textmate", "tomorrow", "tomorrow_night", "tomorrow_night_blue", "tomorrow_night_bright", "tomorrow_night_eighties", "twilight", "vibrant_ink", "xcode"],
      readOnly: false,
      //是否只读
      options: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        tabSize: 2,
        showPrintMargin: false,
        fontSize: 20
      }
    });
    const dataForm = reactive({
      textareashow: '{"A":"A1"}'
    });
    const editorInit = () => {
      try {
        dataForm.textareashow = JSON.stringify(JSON.parse(dataForm.textareashow), null, 2);
      } catch (e) {
        console.log(`JSON字符串错误：${e.message}`);
      }
    };
    const getList = () => {
      $.ajax({
        url: $constant.baseUrl + "/user/bot/getList/",
        type: "GET",
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        success(resp) {
          bots.value = resp;
          for (let bot of bots.value) {
            bot.createtime = bot.createtime.substring(0, 10);
          }
        },
        error(jqXHR) {
          $common.failedRequest(jqXHR);
        }
      });
    };
    getList();
    const add_bot_cancel = () => {
      botAdd.title = "";
      botAdd.description = "";
      botAdd.content = "";
    };
    const add_bot = () => {
      if ($common.isEmpty(botAdd.title)) {
        $common.Error("bot标题不能为空");
        return;
      }
      if ($common.isEmpty(botAdd.content)) {
        $common.Error("bot代码不能为空");
        return;
      }
      $.ajax({
        url: $constant.baseUrl + "/user/bot/addBot/",
        type: "post",
        data: {
          title: botAdd.title,
          description: botAdd.description,
          content: botAdd.content
        },
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        success(resp) {
          if (resp.error_message === "success") {
            botAdd.title = "";
            botAdd.description = "";
            botAdd.content = "";
            Modal.getInstance("#add-bot-btn").hide(); //关闭Modal的API
            getList();
            $common.Success("添加bot成功");
          } else {
            $common.Error(resp.error_message);
          }
        },
        error(jqXHR) {
          $common.failedRequest(jqXHR);
        }
      });
    };
    const remove = bot_id => {
      $common.confirmAction("警告", "你确定删除此bot?", "red").then(result => {
        if (result) {
          $.ajax({
            url: $constant.baseUrl + "/user/bot/remove/",
            type: "post",
            data: {
              bot_id: bot_id
            },
            headers: {
              Authorization: "Bearer " + store.state.user.token
            },
            success(resp) {
              if (resp.error_message === "success") {
                $common.Success("删除bot成功");
                getList();
              } else {
                $common.Error(resp.error_message);
                getList();
              }
            },
            error(jqXHR) {
              $common.failedRequest(jqXHR);
            }
          });
        }
      });
    };
    const update_bot = bot => {
      $.ajax({
        url: $constant.baseUrl + "/user/bot/update/",
        type: "post",
        data: {
          bot_id: bot.id,
          title: bot.title,
          description: bot.description,
          content: bot.content
        },
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        success(resp) {
          if (resp.error_message === "success") {
            Modal.getInstance('#update-bot-modal' + bot.id).hide(); //关闭Modal的API
            getList();
            $common.Success("更新bot成功!");
          } else {
            $common.Error(resp.error_message);
          }
        },
        error(jqXHR) {
          $common.failedRequest(jqXHR);
        }
      });
    };
    return {
      bots,
      botAdd,
      aceConfig,
      remove,
      add_bot,
      getList,
      update_bot,
      editorInit,
      add_bot_cancel
    };
  }
};