import { ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import $ from 'jquery';
export default {
  setup() {
    const $constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    const store = useStore();
    let match_btn_info = ref("开始匹配");
    let bots = ref([]);
    let bot_id = ref(""); // ""表示未选中
    let robot = ref("");
    let playType = ref("onlinePlay");
    const opPlayType = e => {
      bot_id.value = "";
      robot.value = "";
      if (e === 0) playType.value = "onlinePlay";else playType.value = "offlinePlay";
    };
    const click_match_btn = () => {
      if (playType.value === "onlinePlay" && bot_id.value === "") {
        $common.Error("请选择上阵选手", 1500);
        return;
      } else if (playType.value === "offlinePlay") {
        if (robot.value === "") {
          $common.Error("请选择您的对手", 1500);
          return;
        } else if (bot_id.value === "") {
          $common.Error("请选择上阵选手", 1500);
          return;
        }
      }
      if (match_btn_info.value === "开始匹配") {
        if (!navigator.onLine) {
          $common.Error("网络未连接，匹配失败!");
          return;
        }

        // 处理bot_id
        let botId = -1;
        if (bot_id.value !== "亲自上阵") {
          for (let bot of bots.value) {
            if (bot.title === bot_id.value) botId = bot.id;
          }
        }
        if (store.state.pk.socket.readyState === WebSocket.OPEN) {
          match_btn_info.value = "取消";
          store.state.pk.socket.send(JSON.stringify({
            // 将JSON转化为字符串
            event: "start-matching",
            playType: playType.value,
            bot_id: botId,
            robot: robot.value
          }));
        } else {
          $common.Error("您已掉线，请刷新浏览器重试~");
        }
      } else {
        if (!navigator.onLine) {
          $common.Error("网络未连接，取消匹配失败!");
          return;
        }
        match_btn_info.value = "开始匹配";
        store.state.pk.socket.send(JSON.stringify({
          event: "stop-matching"
        }));
      }
    };
    const refresh_bots = () => {
      $.ajax({
        url: $constant.baseUrl + "/user/bot/getList/",
        type: "GET",
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        success(resp) {
          bots.value = resp;
        },
        error() {
          if (!navigator.onLine) {
            $common.Error("网络未连接，bot列表刷新失败！");
          } else {
            $common.Error("服务器繁忙，请稍后再试。");
          }
        }
      });
    };
    refresh_bots();
    return {
      match_btn_info,
      click_match_btn,
      bots,
      bot_id,
      robot,
      playType,
      opPlayType
    };
  }
};