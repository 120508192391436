import "core-js/modules/es.array.push.js";
import { ref, getCurrentInstance, watch, onMounted } from "vue";
import $ from "jquery";
import { useStore } from "vuex";
import ChatViewVue from '@/components/friends/ChatView.vue';
import SystemChat from '@/components/friends/SystemChat.vue';
export default {
  components: {
    ChatViewVue,
    SystemChat
  },
  setup() {
    const constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    const common = getCurrentInstance().appContext.config.globalProperties.$common;
    const store = useStore();
    let socket = store.state.user.chatSocket;
    onMounted(() => {
      socket.onmessage = msg => {
        const data = JSON.parse(msg.data);
        if (data.event === "your friend is online") {
          // 伙伴上下线
          getFriends();
        }
      };
      if (window.innerWidth < 700) {
        f.value = false;
      }
    });
    let f = ref(true);
    let detailsId = ref(0); // (0, 1, 2)分别表示打开聊天、好友列表、群聊
    let lastDetailsId = -1;
    let friends = ref([]);
    let focusId = ref(0); // 当前点开详情的用户:(-1：好友请求，0:未选择，1:第一个朋友)
    let chatId = ref(-1); // 当前聊天的对象(-1: 系统消息，0：未选择，1：第一个朋友)
    let lastChatId = ref(-1); // 上一个聊天对象
    let st = ref([]); // 伙伴在线状态
    let unReadCnt = ref([]); // 未读消息数量
    let fName = ref("");
    let findFriends = ref([]);

    // 未读消息++ (发送者Id)
    const newUnRead = send_id => {
      for (let i = 0; i < friends.value.length; i++) {
        if (friends.value[i].id == send_id) {
          unReadCnt.value[i]++;
          break;
        }
      }
    };
    let size_i = 0;
    const main = ref(null);
    const friendsRef = ref(null);
    const friends2Ref = ref(null);
    const changeSize = () => {
      if (window.innerWidth > 1023) {
        if (size_i === 0) {
          main.value.style.width = '80vw';
          main.value.style.height = '88vh';
          main.value.style.marginTop = '0px';
          friendsRef.value.style.height = '64.5vh';
          friends2Ref.value.style.height = '72.5vh';
        } else {
          main.value.style.width = '58vw';
          main.value.style.height = '69.5vh';
          main.value.style.marginTop = '50px';
          friendsRef.value.style.height = '45vh';
          friends2Ref.value.style.height = '53vh';
        }
        size_i = (size_i + 1) % 2;
      }
    };
    const openDetails = () => {
      f.value = !f.value;
    };
    const changeDetails = id => {
      lastDetailsId = detailsId.value;
      detailsId.value = id;
      if (!f.value) f.value = !f.value; // 打开详情
      else if (lastDetailsId === detailsId.value) f.value = !f.value; // 关闭详情
    };

    const changeFocus = id => {
      focusId.value = id;
    };
    const changeChatId = id => {
      lastChatId.value = chatId.value;
      chatId.value = id;
      let chat_id_backend = id > 0 ? friends.value[id - 1].id : 0;
      // 通知后端，改变聊天对象
      socket.send(JSON.stringify({
        event: "change_chat_id",
        user_id: store.state.user.id,
        chat_id: chat_id_backend
      }));
      setTimeout(() => {
        unReadCnt.value[id - 1] = 0;
      }, 3000);
    };
    const openChat = () => {
      detailsId.value = 0;
      changeChatId(focusId.value);
      focusId.value = 0;
    };
    const getFriends = () => {
      // 获取好友
      unReadCnt.value = [];
      $.ajax({
        url: constant.baseUrl + "/user/friends/getList/",
        type: "GET",
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        success(resp) {
          if (resp.error_message === "success") {
            friends.value = resp.friends;
            for (let o of friends.value) {
              unReadCnt.value.push(o.unReadCnt);
            }
            for (let i in friends.value) {
              st.value[i] = friends.value[i].is_online;
            }
          }
        },
        error(jqXHR) {
          common.failedRequest(jqXHR);
        }
      });
    };
    getFriends();
    const handleKeydown = event => {
      // 回车触发
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        findFriend();
      }
    };
    watch(fName, newName => {
      if (newName === "") {
        setTimeout(() => {
          findFriends.value = [];
        }, 200);
      }
    });
    const findFriend = () => {
      // 查找用户
      if (common.isEmpty(fName.value)) {
        common.Error("没有名字我可帮不了你喔~");
        return;
      }
      $.ajax({
        url: constant.baseUrl + "/user/friends/getUsers/",
        type: "get",
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        data: {
          username: fName.value
        },
        success(resp) {
          // 这里后端返回来的是昵称和账号，自己处理一下
          for (let i in resp.users) {
            if (!resp.users[i].username.includes(fName.value)) {
              resp.users[i].username = resp.users[i].nickname;
            }
          }
          findFriends.value = resp.users;
          if (findFriends.value.length === 0) {
            common.Error("系统里没有这个用户喔~");
          }
        },
        error(jqXHR) {
          common.failedRequest(jqXHR);
        }
      });
    };
    const addFriend = friendId => {
      // 添加朋友
      common.confirmAction("确认操作", "你确定添加好友?", "#41ca6a").then(result => {
        if (result) {
          $.ajax({
            url: constant.baseUrl + "/user/friends/add/",
            type: "post",
            headers: {
              Authorization: "Bearer " + store.state.user.token
            },
            data: {
              friend_id: friendId
            },
            success(resp) {
              if (resp.error_message === "success") {
                let len = findFriends.value.length;
                for (let i = 0; i < len; i++) {
                  if (findFriends.value[i].id == friendId) {
                    findFriends.value[i].isFriend = true;
                    break;
                  }
                }
                getFriends();
              } else {
                common.Error(resp.error_message);
              }
            },
            error(jqXHR) {
              common.failedRequest(jqXHR);
            }
          });
        }
      });
    };
    const deleteFriend = (friendId, nickname) => {
      // 删除朋友
      if (common.isEmpty(friendId)) {
        return;
      }
      common.confirmAction("警告", "你确定删除好友" + nickname + "吗? 删除后聊天记录也将永久删除。", 'red').then(result => {
        if (result) {
          $.ajax({
            url: constant.baseUrl + "/user/friends/remove/",
            type: "post",
            headers: {
              Authorization: "Bearer " + store.state.user.token
            },
            data: {
              friend_id: friendId
            },
            success(resp) {
              if (resp.error_message === "success") {
                focusId.value = focusId.value - 1; // 删除了改focusId
                getFriends();
              }
            },
            error(jqXHR) {
              common.failedRequest(jqXHR);
            }
          });
        }
      });
    };
    return {
      openChat,
      addFriend,
      newUnRead,
      changeSize,
      findFriend,
      changeFocus,
      openDetails,
      deleteFriend,
      changeChatId,
      changeDetails,
      handleKeydown,
      f,
      st,
      main,
      fName,
      chatId,
      common,
      focusId,
      friends,
      unReadCnt,
      detailsId,
      lastChatId,
      friendsRef,
      friends2Ref,
      findFriends
    };
  }
};