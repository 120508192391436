import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-634179c0"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["width", "height"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "PicCode",
    style: _normalizeStyle({
      width: $props.width + 'px',
      height: $props.height + 'px'
    }),
    ref: "CharacterVerification"
  }, [_createElementVNode("canvas", {
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.refresh && $setup.refresh(...args)),
    width: $props.width,
    height: $props.height,
    ref: "verifyCanvas",
    style: {
      "cursor": "pointer",
      "border-radius": "5px"
    }
  }, null, 8, _hoisted_1)], 4);
}