import $ from 'jquery';
import { useStore } from 'vuex';
import { ref, onMounted, getCurrentInstance, onUnmounted } from 'vue';
export default {
  setup() {
    const $constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    const store = useStore();

    // 查询条件
    let Time = ref('');
    let Module = ref('');
    let operator = ref('');
    let NickName = ref('');
    const modules = ref([]);
    const operators = ref([]);
    const Times = [{
      id: 0,
      value: "今天"
    }, {
      id: -1,
      value: "昨天"
    }, {
      id: -2,
      value: "前天"
    }, {
      id: -3,
      value: "大前天"
    }];
    const getQueryCondition = () => {
      $.ajax({
        url: $constant.baseUrl + "/admin/monitor/getQueryCondition",
        type: "get",
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        success(resp) {
          if (resp.error_message === "success") {
            modules.value = resp.modules;
            operators.value = resp.operators;
          } else {
            $common.Error(resp.error_message);
          }
        },
        error() {
          if (!navigator.onLine) {
            $common.Error("网络未连接，请刷新重试~");
          }
        }
      });
    };
    const clear = () => {
      Time.value = "";
      Module.value = "";
      operator.value = "";
      NickName.value = "";
    };
    const getList = page => {
      $.ajax({
        url: $constant.baseUrl + "/admin/monitor/getList",
        type: "get",
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        data: {
          page: page,
          module: Module.value,
          operator: operator.value,
          time: Time.value,
          nickname: NickName.value
        },
        success(resp) {
          if (resp.error_message === "success") {
            list.value = resp.list;
            pageCount.value = resp.count * 10;
          } else {
            $common.Error(resp.error_message);
          }
        },
        error() {
          if (!navigator.onLine) {
            $common.Error("网络未连接，请刷新重试~");
          }
        }
      });
    };
    const checkWidth = () => {
      if (window.innerWidth < 767) {
        MaxShow.value = 5;
        isSmall.value = true;
      } else {
        MaxShow.value = 7;
        isSmall.value = false;
      }
    };
    onMounted(() => {
      window.addEventListener('resize', checkWidth);
      checkWidth();
    });
    onUnmounted(() => {
      window.removeEventListener('resize', checkWidth);
    });

    // 分页相关
    let list = ref([]);
    let pageCount = ref(10);
    let MaxShow = ref(7);
    let isSmall = ref(false);
    let current_page = ref(1);
    const changePage = page => {
      current_page.value = page;
      getList(page);
    };
    getList(1);
    getQueryCondition();
    return {
      list,
      pageCount,
      MaxShow,
      isSmall,
      current_page,
      Time,
      Times,
      Module,
      modules,
      operator,
      operators,
      NickName,
      clear,
      getList,
      changePage
    };
  }
};