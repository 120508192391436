import PlayGround from '../../components/pk/PlayGround';
import MatchGround from '../../components/pk/MatchGround';
import ResultBoard from '../../components/pk/ResultBoard';
import { ref, onMounted, onUnmounted, getCurrentInstance } from 'vue'; // 当组件挂载时加载和当组件卸载时加载
import { useStore } from 'vuex';
export default {
  name: "PkIndexView",
  components: {
    PlayGround,
    MatchGround,
    ResultBoard
  },
  setup() {
    const $constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    const store = useStore();
    const socketUrl = $constant.pkSocketUrl + store.state.user.token;
    store.commit("updateStatus", "matching");
    store.commit("updateLoser", "none");
    store.commit("updateIsRecord", false);

    // 重置倒计时函数
    const timer = ref(10);
    let f = true; // 递归开关
    const evalTime = () => {
      const timeOk = (new Date().getSeconds() + 10) % 60;
      const step = () => {
        if (!f) return;
        const now = new Date().getSeconds();
        if (now === timeOk) {
          timer.value = 0;
          return;
        }
        timer.value = (timeOk - now + 60) % 60;
        requestAnimationFrame(step);
      };
      step();
    };
    let socket = null;
    let round = ref(0);
    onMounted(() => {
      // 挂载时
      store.commit("updateOpponent", {
        opponent_username: "您的对手",
        opponent_nickname: "您的对手",
        opponent_photo: "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png"
      });
      socket = new WebSocket(socketUrl);
      socket.onopen = () => {
        store.commit("updateSocket", socket);
      };
      socket.onmessage = msg => {
        const data = JSON.parse(msg.data);
        round.value++;
        if (data.event === "match-success") {
          // 匹配成功
          store.commit("updateOpponent", {
            opponent_username: data.opponent_username,
            opponent_photo: data.opponent_photo,
            opponent_nickname: data.opponent_nickname
          });
          store.commit("updateGame", data.game);
          setTimeout(() => {
            store.commit("updateStatus", "playing");
            round.value = 1;
            f = true;
            evalTime();
          }, 2000);
        } else if (data.event === "move") {
          // 游戏中
          evalTime();
          const [snake0, snake1] = store.state.pk.gameObject.snakes;
          snake0.set_direction(data.a_direction);
          snake1.set_direction(data.b_direction);
        } else if (data.event === "result") {
          // 结束游戏
          f = false;
          const gameObject = store.state.pk.gameObject;
          const [snake0, snake1] = gameObject.snakes;
          // TODO 这里把最后一步传过来，然后走完，然后再死
          if (data.loser === "all" || data.loser === "A") {
            snake0.status = "die";
          }
          if (data.loser === "all" || data.loser === "B") {
            snake1.status = "die";
          }
          store.commit("updateLoser", data.loser);
        }
      };
      socket.onerror = function (event) {
        console.error('WebSocket error observed:', event);
      };
    });
    onUnmounted(() => {
      // 当卸载时必须卸载，不然每次打开都会建立一个新的链接，会产生很多冗余链接
      if (socket) {
        socket.close();
        store.commit("updateStatus", "matching"); // 如果切屏了，那么就需要重新匹配
      }
    });

    return {
      round,
      timer
    };
  }
};