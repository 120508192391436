import "core-js/modules/es.array.push.js";
import { useStore } from 'vuex';
import { onMounted, ref, watch, getCurrentInstance } from 'vue';
export default {
  props: {
    friend: {
      type: Object,
      required: true
    },
    chatId: {
      type: Number,
      required: true
    },
    lastChatId: {
      type: Number,
      required: true
    }
  },
  setup(props, context) {
    const store = useStore();
    let socket = store.state.user.chatSocket;
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    const meId = store.state.user.id;
    let messages = ref([]);
    let msg = ref("");
    let error_message = ref("");

    // 每次切换聊天对象都需要初始化
    const initChat = () => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify({
          event: "get_history_chat",
          send_id: store.state.user.id,
          receive_id: props.friend.id
        }));
      }
    };
    watch(() => props.chatId, () => {
      // 改变聊天对象
      initChat();
    });
    onMounted(() => {
      initChat();
      opH();
      socket.onmessage = msg => {
        const data = JSON.parse(msg.data);
        // 拉取聊天记录
        if (data.event === "receive history chat") {
          messages.value = data.records;
          let len = messages.value.length;
          if (len < 3) {
            for (let i = 0; i < len; i++) {
              messages.value[i].createtime = $common.getDateDiff(messages.value[i].createtime);
            }
          } else {
            // 处理消息的发送时间: 第一条消息打标记，后面每间隔5分钟打一次
            let st = messages.value[0].createtime;
            messages.value[0].createtime = $common.getDateDiff(messages.value[0].createtime);
            messages.value[len - 1].createtime = $common.getDateDiff(messages.value[len - 1].createtime);
            for (let i = 1; i < len - 1; i++) {
              let ed = messages.value[i].createtime;
              if (!$common.isLowFive(st, ed)) {
                st = ed;
                messages.value[i].createtime = $common.getDateDiff(messages.value[i].createtime);
              } else {
                messages.value[i].createtime = "";
              }
            }
          }
          messages.value = messages.value.reverse();
          opH();
        }
        // 收到新的消息
        else if (data.event === "receive new message") {
          // 新消息必须是(你发 || 我发)的才可以接受
          if (data.new_message.sendId === props.friend.id || data.new_message.sendId === parseInt(meId)) {
            let len = messages.value.length;
            messages.value.push(data.new_message);
            messages.value[len].createtime = ""; // 新消息不设置时间
            opH();
          }
          // 否则就是未读消息+1
          else {
            // 这里调用父组件的函数进行操作
            context.emit("newUnRead", data.new_message.sendId);
          }
        } else if (data.event === "msg too long") {
          error_message.value = "单条消息不能超过1000字";
          setTimeout(() => {
            error_message.value = "";
          }, 5000);
        }
      };
      socket.onclose = () => {
        initChat();
      };
    });
    const handleKeydown = event => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault(); // 阻止默认的换行行为
        send();
      }
    };
    const send = () => {
      if ($common.isEmpty(msg.value)) {
        error_message.value = "没有输入任何文本";
        setTimeout(() => {
          error_message.value = "";
        }, 3000);
        return;
      }
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify({
          event: "send_message",
          send_id: meId,
          receive_id: props.friend.id,
          msg: msg.value
        }));
        msg.value = "";
      } else {
        $common.Error("消息发送失败！请刷新浏览器~");
      }
    };
    const opH = () => {
      setTimeout(() => {
        const test = document.getElementById('test'); // 调整消息视角
        if (test != null) {
          test.scrollTop = test.scrollHeight;
        }
      }, 2);
    };
    return {
      msg,
      meId,
      props,
      messages,
      error_message,
      send,
      handleKeydown
    };
  }
};