import { reactive, toRefs } from 'vue';
export default {
  props: {
    onInfo: {
      type: String,
      default: ''
    },
    offInfo: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    nodeStyle: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:checked', 'change'],
  setup(props, {
    emit
  }) {
    const state = reactive({
      switchChecked: props.checked
    });
    function onSwitch() {
      if (props.disabled) {
        return;
      }
      state.switchChecked = !state.switchChecked;
      emit('update:checked', state.switchChecked);
      emit('change', state.switchChecked);
    }

    // 将 reactive 状态解构出来便于在模板中访问
    return {
      ...toRefs(state),
      onSwitch
    };
  }
};