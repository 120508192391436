import "core-js/modules/es.array.push.js";
import PicCode from "@/components/utils/PicCode.vue";
import { ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import router from "@/router";
import $ from "jquery";
export default {
  components: {
    PicCode
  },
  setup() {
    const store = useStore();
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    const $constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    let code1 = ref("");
    let email = ref("");
    let code2 = ref("");
    let f = ref(false);
    let time = ref(60);
    const PicCode = ref(null);
    const checkValue = () => {
      if ($common.isEmpty(email.value)) {
        $common.Error("邮箱不能为空");
        return false;
      }
      if (!/^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/.test(email.value)) {
        $common.Error("邮箱格式不合法");
        return false;
      }
      if ($common.isEmpty(code1.value)) {
        $common.Error("图形验证码不能为空");
        return false;
      }
      if (!PicCode.value.validate(code1.value)) {
        code1.value = "";
        $common.Error("图形验证码错误!");
        return false;
      }
      return true;
    };
    const getCode = () => {
      if (!checkValue()) return;
      $.ajax({
        url: $constant.baseUrl + "/user/account/getEmailCode/",
        type: "get",
        data: {
          email: email.value
        },
        success(resp) {
          if (resp.error_message === "success") {
            $common.Success("发送验证码成功,请注意查收~");
            f.value = true;
            const startTime = new Date(); // 保存开始时间
            let sum = 60; // 倒计时时长，单位秒
            const step = () => {
              let now = new Date();
              let dir = (now - startTime) / 1000; // 计算经过的秒数
              time.value = parseInt(sum - dir);
              if (time.value <= 0) {
                f.value = false;
                return;
              }
              requestAnimationFrame(step);
            };
            step();
          } else {
            $common.Error(resp.error_message);
          }
        },
        error() {
          if (!navigator.onLine) {
            $common.Error("网络未连接，请刷新重试~");
          } else {
            $common.Error("服务器繁忙，请稍后再试。");
          }
        }
      });
    };
    const login = () => {
      if (!checkValue()) return;
      if (code2.value.length < 6) {
        $common.Error("验证码不足6位!");
        return;
      }
      $.ajax({
        url: $constant.baseUrl + "/user/account/loginByEmail/",
        type: "post",
        data: {
          email: email.value,
          code: code2.value
        },
        success(resp) {
          if (resp.error_message === "success") {
            let token = resp.token;
            localStorage.setItem("jwt_token", token);
            store.commit("updateToken", token);
            store.dispatch("getInfo", {
              success() {
                router.push({
                  name: "home"
                });
              },
              error() {
                $common.Error("获取Info失败啦");
              }
            });
          } else {
            $common.Error(resp.error_message);
          }
        },
        error() {
          if (!navigator.onLine) {
            $common.Error("网络未连接，请刷新重试~");
          } else {
            $common.Error("服务器繁忙，请稍后再试。");
          }
        }
      });
    };
    const goBack = () => {
      window.history.back();
    };
    return {
      login,
      goBack,
      getCode,
      f,
      time,
      code1,
      code2,
      email,
      PicCode
    };
  }
};