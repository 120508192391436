import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5086c73f"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "card card-body"
};
const _hoisted_2 = {
  class: "post-title"
};
const _hoisted_3 = {
  class: "post-author"
};
const _hoisted_4 = {
  class: "post-author-name"
};
const _hoisted_5 = {
  class: "post-createtime"
};
const _hoisted_6 = ["innerHTML"];
const _hoisted_7 = {
  class: "bottom-div"
};
const _hoisted_8 = {
  key: 1,
  class: "expand"
};
const _hoisted_9 = {
  class: "favour-cnt"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: "collapse-text",
    style: _normalizeStyle({
      maxHeight: $setup.expand ? $setup.maxHeight + 'px' : $setup.defaultHeight + 'px',
      maxWidth: $props.width
    }),
    ref: "collapseContent"
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.post.title), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString($props.post.nickname), 1), _createElementVNode("div", _hoisted_5, _toDisplayString($setup.time), 1)]), _createElementVNode("div", {
    class: "post-content",
    innerHTML: $setup.compiledContent
  }, null, 8, _hoisted_6)])], 4), _createElementVNode("div", _hoisted_7, [$setup.f ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "expand",
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.onExpand && $setup.onExpand(...args)),
    style: {
      "font-family": "SmileySans"
    }
  }, _toDisplayString($setup.expand ? "收起" : "展开"), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_8)), _createElementVNode("div", _hoisted_9, _toDisplayString($setup.favour), 1), _createElementVNode("div", {
    class: _normalizeClass(($setup.isLike == null ? $props.post.like : $setup.isLike) ? 'favour favour-like' : 'favour favour-unlike'),
    onClick: _cache[1] || (_cache[1] = (...args) => $setup.addFavour && $setup.addFavour(...args))
  }, null, 2)])]);
}