import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3e86def4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "m-switch-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.onSwitch && $setup.onSwitch(...args)),
    class: _normalizeClass(['m-switch', {
      'switch-checked': _ctx.switchChecked,
      'disabled': $props.disabled
    }])
  }, [_createElementVNode("div", {
    class: _normalizeClass(['u-switch-inner', _ctx.switchChecked ? 'inner-checked' : 'inner-unchecked'])
  }, _toDisplayString(_ctx.switchChecked ? $props.onInfo : $props.offInfo), 3), _createElementVNode("div", {
    class: _normalizeClass(['u-node', {
      'node-checked': _ctx.switchChecked
    }]),
    style: _normalizeStyle($props.nodeStyle)
  }, [_renderSlot(_ctx.$slots, "node", {}, undefined, true)], 6)], 2)]);
}