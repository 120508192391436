import "core-js/modules/es.array.push.js";
import { ref, onMounted, getCurrentInstance } from 'vue';
import $ from "jquery";
import CollapsiblePost from '@/components/home/CollapsiblePost.vue';
import router from '@/router';
export default {
  components: {
    CollapsiblePost
  },
  setup() {
    const $constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    const main = ref();
    const posts = ref([]);
    let mintime = ref(1000000000000000);
    let offset = ref(0);
    let isLoading = ref(false);
    let f = ref(true);
    let onLine = ref(true);
    const handleScroll = () => {
      const {
        scrollTop,
        scrollHeight,
        clientHeight
      } = main.value;
      if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 10 && !isLoading.value && onLine.value) {
        addPosts();
      }
    };
    const addPosts = () => {
      isLoading.value = true;
      setTimeout(() => {
        $.ajax({
          url: $constant.baseUrl + "/square/getList/",
          type: "GET",
          data: {
            mintime: mintime.value,
            offset: offset.value
          },
          success(resp) {
            if (resp.error_message === "success") {
              posts.value.push(...resp.posts);
              mintime.value = resp.mintime;
              offset.value = resp.offset;
              checkLike();
            } else {
              f.value = false;
            }
          },
          error() {
            if (!navigator.onLine) {
              $common.Error("网络未连接，请联网后重试~");
            } else {
              $common.Error("更新帖子失败~");
            }
            onLine.value = false; // 没网络需要刷新使用了
          }
        });

        setTimeout(() => {
          // 给post赋值争取时间，不然还没有将div撑开又调用addPost
          isLoading.value = false;
        }, 200);
      }, 800);
    };
    onMounted(() => {
      main.value.addEventListener('scroll', handleScroll);
      $.ajax({
        url: $constant.baseUrl + "/square/getList/",
        type: "GET",
        data: {
          mintime: mintime.value,
          offset: offset.value
        },
        success(resp) {
          if (resp.error_message === "success") {
            posts.value.push(...resp.posts);
            mintime.value = resp.mintime;
            offset.value = resp.offset;
            checkLike();
            if (resp.posts.length < 3) {
              addPosts();
            }
          }
        },
        error() {
          if (!navigator.onLine) {
            $common.Error("网络未连接，请联网后重试~");
          } else {
            $common.Error("接口请求失败!");
          }
        }
      });
    });
    const checkLike = () => {
      if (localStorage.getItem("jwt_token") != null) {
        let q = [];
        for (let i = posts.value.length - 1, j = 0; j < 5 && j < posts.value.length; i--, j++) {
          q[j] = posts.value[i].id;
        }
        $.ajax({
          url: $constant.baseUrl + "/square/check/",
          type: "get",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token")
          },
          data: {
            "q": JSON.stringify(q)
          },
          success(resp) {
            let res = [];
            Object.entries(resp).forEach(([key, value]) => {
              res[key] = value;
            });
            for (let i = posts.value.length - 1, j = 0; j < 5 && j < posts.value.length; i--, j++) {
              posts.value[i].like = res[posts.value[i].id];
            }
          },
          error() {
            if (!navigator.onLine) {
              $common.Error("网络未连接，请联网后重试~", 1000);
            } else {
              $common.Error("服务器繁忙，请稍后再试。", 1000);
            }
          }
        });
      }
    };
    const add_a_post = () => {
      router.push({
        name: "user_profile_add_post"
      });
    };
    return {
      main,
      posts,
      isLoading,
      f,
      add_a_post
    };
  }
};