export default {
    state: {
        f: false,
    },

    getters: {
    },

    mutations: {
        openAdmin(state) {
            state.f = true;
        },
        closeAdmin(state) {
            state.f = false;
        },
    },

    actions: {

    },

    modules: {
    }
}