import { useStore } from "vuex";
import { ref, getCurrentInstance } from "vue";
import $ from "jquery";
export default {
  setup() {
    const $constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    const store = useStore();
    let op = ref(0); // 0表示展示网站背景
    const changeOp = k => {
      op.value = k;
    };
    const changeBack = icon => {
      store.commit("updateBackIcon", icon);
      $.ajax({
        url: $constant.baseUrl + "/user/set/setBackIcon/",
        type: "post",
        data: {
          icon: icon
        },
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        success(resp) {
          if (resp.error_message === "success") {
            $common.Success("已成功更换背景!", 1000);
          } else {
            $common.Error(resp.error_message);
          }
        },
        error(jqXHR) {
          $common.failedRequest(jqXHR);
        }
      });
    };
    return {
      op,
      changeOp,
      changeBack
    };
  }
};