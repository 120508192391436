import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-45ff6033"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "result-board"
};
const _hoisted_2 = {
  key: 0,
  class: "result-board-text element"
};
const _hoisted_3 = {
  key: 1,
  class: "result-board-text element"
};
const _hoisted_4 = {
  key: 2,
  class: "result-board-text element"
};
const _hoisted_5 = {
  key: 3,
  class: "result-board-text element"
};
const _hoisted_6 = {
  class: "result-board-button"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.$store.state.pk.loser === 'all' ? (_openBlock(), _createElementBlock("div", _hoisted_2, " 平局 ")) : _ctx.$store.state.pk.loser === 'A' && _ctx.$store.state.pk.a_id === parseInt(_ctx.$store.state.user.id) ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Lose ")) : _ctx.$store.state.pk.loser === 'B' && _ctx.$store.state.pk.b_id === parseInt(_ctx.$store.state.user.id) ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Lose ")) : (_openBlock(), _createElementBlock("div", _hoisted_5, " Win ")), _createElementVNode("div", _hoisted_6, [_createElementVNode("button", {
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.restart && $setup.restart(...args)),
    class: "btn btn-warning btn-lg",
    type: "button"
  }, " 再来 ")])]);
}