import "core-js/modules/es.array.push.js";
import $ from 'jquery';
import { useStore } from 'vuex';
import { ref, getCurrentInstance, onMounted, onUnmounted } from 'vue';
import router from '../../router/index';
export default {
  name: "RecordIndexView",
  setup() {
    const $constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    const store = useStore();
    let records = ref([]); // 当前获取到的records
    let current_page = 1;
    let total_records = 0;
    let pages = ref([]); // 一共多少个分页
    let three = ref(null);
    let four = ref(null);
    let five = ref(null);
    const click_page = page => {
      if (page === -2) page = current_page - 1;else if (page === -1) page = current_page + 1;
      let max_pages = parseInt(Math.ceil(total_records / 9));
      if (page >= 1 && page <= max_pages) {
        pull_page(page);
      }
    };
    const update_pages = () => {
      let page_count = parseInt(Math.ceil(total_records / 9));
      let t = [];
      let l, r;
      if (current_page > 2 && current_page < page_count - 1) {
        l = current_page - 2;
        r = current_page + 2;
      } else if (current_page <= 2) {
        l = 1;
        r = 5;
      } else if (current_page >= page_count - 1) {
        l = page_count - 4;
        r = page_count;
      }
      for (let i = Math.max(1, l); i <= Math.min(page_count, r); i++) {
        t.push({
          number: i,
          is_active: i === current_page ? "active" : ""
        });
      }
      pages.value = t;
    };
    const pull_page = page => {
      current_page = page;
      $.ajax({
        url: $constant.baseUrl + "/record/page",
        type: "GET",
        data: {
          "pageNo": page,
          "pageSize": 9,
          "sortBy": "id",
          "isAsc": false
        },
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        success(resp) {
          if (resp.error_message === "success") {
            records.value = resp.records;
            total_records = resp.records_count;
            for (let i = 0; i < records.value.length; i++) {
              records.value[i].record.createtime = $common.getDateDiff(records.value[i].record.createtime);
            }
            update_pages(); //每次打开一个页码，就更新周围2页(只有当前页加载数据，另外两页就是加载个数字)
          }
        },

        error(jqXHR) {
          $common.failedRequest(jqXHR);
        }
      });
    };
    pull_page(current_page);
    const stringTo2D = map => {
      const g = [];
      for (let i = 0, k = 0; i < 13; i++) {
        let line = [];
        for (let j = 0; j < 14; j++, k++) {
          if (map[k] == '0') line.push(0);else line.push(1);
        }
        g.push(line);
      }
      return g;
    };
    const open_record_content = recordId => {
      // 这个函数就可以跳到某个record页面
      for (const record of records.value) {
        if (record.record.id === recordId) {
          store.commit("updateIsRecord", true); // 打开pk页面一定要记得改回去
          store.commit("updateGame", {
            // 存下游戏的记录信息
            gamemap: stringTo2D(record.record.map),
            a_id: record.record.aid,
            a_sx: record.record.asx,
            a_sy: record.record.asy,
            b_id: record.record.bid,
            b_sx: record.record.bsx,
            b_sy: record.record.bsy
          });
          store.commit("updateSteps", {
            a_steps: record.record.asteps,
            b_steps: record.record.bsteps
          });
          store.commit("updateRecordLoser", record.record.loser);
          router.push({
            name: "record_content"
          });
          break;
        }
      }
    };
    const checkWidth = () => {
      if (window.innerWidth < 678) {
        three.value.textContent = "结果";
        four.value.textContent = "时间";
        five.value.textContent = "录像";
      } else {
        three.value.textContent = "对战结果";
        four.value.textContent = "对战时间";
        five.value.textContent = "精彩回放";
      }
    };
    onMounted(() => {
      window.addEventListener('resize', checkWidth);
      checkWidth();
    });
    onUnmounted(() => {
      window.removeEventListener('resize', checkWidth);
    });
    return {
      four,
      five,
      three,
      pages,
      records,
      click_page,
      open_record_content
    };
  }
};