import { ref, onMounted } from 'vue';
export default {
  name: 'PicCode',
  props: {
    width: {
      type: Number,
      default: 150
    },
    height: {
      type: Number,
      default: 40
    },
    type: {
      type: String,
      default: 'blend' // blend:数字字母混合类型、number:纯数字、letter:纯字母
    }
  },

  setup(props, {
    expose
  }) {
    const numArr = '0,1,2,3,4,5,6,7,8,9'.split(',');
    const letterArr = 'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z,A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z'.split(',');
    const code = ref('');
    const CharacterVerification = ref(null);
    const verifyCanvas = ref(null);
    let ctx;
    onMounted(() => {
      refresh();
    });
    const refresh = () => {
      code.value = '';
      const canvas = verifyCanvas.value;
      if (canvas && canvas.getContext) {
        ctx = canvas.getContext('2d');
      } else {
        return;
      }
      ctx.textBaseline = 'middle';
      ctx.fillStyle = randomColor(180, 240);
      ctx.fillRect(0, 0, props.width, props.height);
      let txtArr;
      if (props.type === 'blend') {
        txtArr = numArr.concat(letterArr);
      } else if (props.type === 'number') {
        txtArr = numArr;
      } else {
        txtArr = letterArr;
      }
      for (let i = 1; i <= 4; i++) {
        const txt = txtArr[randomNum(0, txtArr.length)];
        code.value += txt;
        ctx.font = randomNum(props.height / 2, props.height) + 'px SimHei';
        ctx.fillStyle = randomColor(50, 160);
        ctx.shadowOffsetX = randomNum(-3, 3);
        ctx.shadowOffsetY = randomNum(-3, 3);
        ctx.shadowBlur = randomNum(-3, 3);
        ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
        const x = props.width / 5 * i;
        const y = props.height / 2;
        const deg = randomNum(-30, 30);
        ctx.translate(x, y);
        ctx.rotate(deg * Math.PI / 180);
        ctx.fillText(txt, 0, 0);
        ctx.rotate(-deg * Math.PI / 180);
        ctx.translate(-x, -y);
      }
      for (let i = 0; i < 4; i++) {
        ctx.strokeStyle = randomColor(40, 180);
        ctx.beginPath();
        ctx.moveTo(randomNum(0, props.width), randomNum(0, props.height));
        ctx.lineTo(randomNum(0, props.width), randomNum(0, props.height));
        ctx.stroke();
      }
      for (let i = 0; i < props.width / 4; i++) {
        ctx.fillStyle = randomColor(0, 255);
        ctx.beginPath();
        ctx.arc(randomNum(0, props.width), randomNum(0, props.height), 1, 0, 2 * Math.PI);
        ctx.fill();
      }
    };
    const randomColor = (min, max) => {
      const r = randomNum(min, max);
      const g = randomNum(min, max);
      const b = randomNum(min, max);
      return `rgb(${r},${g},${b})`;
    };
    const randomNum = (min, max) => {
      return Math.floor(Math.random() * (max - min) + min);
    };
    const validate = iptCode => {
      const newIptCode = iptCode.toLowerCase();
      const v_code = code.value.toLowerCase();
      if (newIptCode === v_code) {
        return true;
      } else {
        refresh();
        return false;
      }
    };
    expose({
      validate
    });
    return {
      CharacterVerification,
      verifyCanvas,
      refresh
    };
  }
};