import { createStore } from 'vuex'
import ModuleUser from "./user"
import ModulePk from './pk'
import ModuleRecord from './record'
import ModulePost from "./post"
import ModuleAdmin from "./admin"

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user: ModuleUser,
    pk: ModulePk,
    record: ModuleRecord,
    post: ModulePost,
    admin: ModuleAdmin
  }
})
