import "core-js/modules/es.array.push.js";
import { ref, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import $ from "jquery";
import { useStore } from "vuex";
import router from "../../router/index";
import constant from '@/utils/constant';
export default {
  setup() {
    const $constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    const store = useStore();
    const inputPlace = ref("");
    const sex = ref("");
    const name = ref("");
    const sexs = [{
      value: 'man',
      label: '男'
    }, {
      value: 'woman',
      label: '女'
    }, {
      value: 'diecy',
      label: '薛定谔的猫'
    }];
    const clearParams = () => {
      sex.value = "";
      name.value = "";
    };

    // 分页相关
    let pageCount = ref(0);
    let maxShow = ref(7); // 下面按钮的数量
    let cnt = ref(9); // 每页个数
    let isSmall = ref(false);
    let current_page = ref(1);
    let users = ref();
    const changePage = page => {
      current_page.value = page;
      getList(page);
    };
    const checkWidth = () => {
      if (window.innerWidth < 767) {
        inputPlace.value = "请输入用户名";
        maxShow.value = 5;
        isSmall.value = true;
        w1.value = 350;
      } else if (window.innerWidth > 768 && window.innerWidth < 1024) {
        inputPlace.value = "请输入";
        maxShow.value = 7;
        isSmall.value = false;
        w1.value = 800;
      } else {
        inputPlace.value = "请输入";
        maxShow.value = 7;
        isSmall.value = false;
        w1.value = 800;
      }
    };
    onMounted(() => {
      window.addEventListener('resize', checkWidth);
      checkWidth();
    });
    onUnmounted(() => {
      window.removeEventListener('resize', checkWidth);
    });
    const getList = page => {
      $.ajax({
        url: $constant.baseUrl + "/admin/userControl/getList",
        type: "get",
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        data: {
          page: page,
          cnt: cnt.value,
          name: name.value,
          sex: sex.value
        },
        success(resp) {
          if (resp.error_message === "success") {
            users.value = resp.users;
            pageCount.value = resp.count;
          } else {
            $common.Error(resp.error_message);
          }
        },
        error() {
          if (!navigator.onLine) {
            $common.Error("网络未连接，请刷新重试~");
          }
        }
      });
    };
    getList(1);
    const search = () => {
      current_page.value = 1;
      getList(1);
    };
    const deleteUser = user_id => {
      $common.confirmAction("警告!", "你确定删除此用户? 将同步删除该用户的用户信息、文章、好友关系、聊天记录等关联信息!", "red").then(result => {
        if (result) {
          $.ajax({
            url: constant.baseUrl + "/admin/userControl/deleteById",
            type: "post",
            headers: {
              Authorization: "Bearer " + store.state.user.token
            },
            data: {
              user_id: user_id
            },
            success(resp) {
              if (resp.error_message === "success") {
                $common.Success("删除成功!", 1500);
                getList(current_page.value);
              } else {
                $common.Error(resp.error_message);
              }
            },
            error() {
              if (!navigator.onLine) {
                $common.Error("网络未连接，请检查网络设置。");
              } else {
                // 跨域||403
                localStorage.removeItem("jwt_token");
                $common.Error("登录凭证已过期，请重新登录~", 3500);
                router.push({
                  name: "user_account_login"
                });
              }
            }
          });
        }
      });
    };

    // 查看弹窗相关的
    const f1 = ref(false);
    const w1 = ref(800);
    const user1 = ref({});
    const viewUser = user => {
      user1.value = user;
      f1.value = true;
    };

    // 修改弹窗相关的
    const f2 = ref(false);
    const user2 = ref({});
    const updateUser = user => {
      user2.value = user;
      f2.value = true;
    };
    const update = () => {
      $common.confirmAction("提示!", "请再次确认信息的完整性，然后点击确认保存!", "orange").then(result => {
        if (result) {
          $.ajax({
            url: constant.baseUrl + "/admin/userControl/updateById",
            type: "post",
            headers: {
              Authorization: "Bearer " + store.state.user.token
            },
            contentType: "application/json",
            data: JSON.stringify({
              id: user2.value.id,
              nickname: user2.value.nickname,
              sex: user2.value.sex,
              age: user2.value.age,
              rating: user2.value.rating,
              phone: user2.value.phone,
              email: user2.value.email,
              brief: user2.value.brief,
              state: user2.value.state
            }),
            success(resp) {
              if (resp.error_message === "success") {
                $common.Success("更新成功");
              } else {
                getList(current_page.value);
                $common.Error(resp.error_message);
              }
            },
            error(jqXHR) {
              getList(current_page.value);
              $common.failedRequest(jqXHR);
            }
          });
        }
      });
      f2.value = false;
    };
    return {
      f1,
      f2,
      w1,
      cnt,
      sex,
      sexs,
      name,
      user1,
      user2,
      users,
      isSmall,
      maxShow,
      pageCount,
      inputPlace,
      current_page,
      update,
      search,
      viewUser,
      updateUser,
      deleteUser,
      changePage,
      clearParams
    };
  }
};