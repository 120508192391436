import "core-js/modules/es.array.push.js";
import { ref, onMounted, nextTick, watch, computed, getCurrentInstance } from 'vue';
import MarkdownIt from "markdown-it";
import Prism from "prismjs";
import "prismjs/themes/prism-coy.css";
import mark from "markdown-it-mark";
import $ from "jquery";
import { useStore } from 'vuex';
import router from '@/router';
export default {
  setup() {
    const $constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    const store = useStore();
    let is_edit = ref(true);
    let title = ref("");
    const content = ref("");
    const textAreaRef = ref(null);
    const md = new MarkdownIt({
      html: true,
      breaks: true,
      linkify: true,
      typographer: true,
      highlight: function (str, lang) {
        if (lang && Prism.languages[lang]) {
          try {
            return Prism.highlight(str, Prism.languages[lang], lang);
          } catch (err) {
            console.error(`代码高亮错误: ${err}`);
          }
        }
        return "";
      }
    }).use(mark);
    md.use(function (md) {
      const defaultRender = md.renderer.rules.image;
      let imgCnt = 0; // 初始化计数器
      md.renderer.rules.image = function (tokens, idx, options, env, self) {
        var result = defaultRender(tokens, idx, options, env, self);
        // 检查当前图片是否是第一个
        let firstStyle = '';
        if (imgCnt === 0) {
          firstStyle = 'margin-top: -60px; border-top: 1px dotted grey;';
        }
        imgCnt++;
        return `
                    <div class="responsive-image-container" style="${firstStyle} position: relative; width: 100%;padding-bottom: 35%; overflow: hidden;border-bottom: 1px dotted grey;">
                        <img class="responsive-image" style="position: absolute; top: 0; left: 0; width: 100%; height: 95%; object-fit: contain;" ${result.split("<img ")[1]}
                    </div>
                `;
      };
    });
    const compiledContent = computed(() => {
      return md.render(content.value);
    });
    watch(is_edit, async newVal => {
      if (newVal) {
        await nextTick();
        autoResize(textAreaRef.value);
      }
    });
    // 自动调整textarea的高度
    onMounted(async () => {
      await nextTick();
      autoResize(textAreaRef.value);
    });
    watch(textAreaRef, textArea => {
      if (textArea) {
        textArea.addEventListener("input", () => autoResize(textArea));
      }
    });
    function autoResize(textArea) {
      textArea.style.height = textArea.scrollHeight + 'px';
    }
    // --------------------------------------------------------------------------------

    const changeEdit = () => {
      is_edit.value = !is_edit.value;
    };
    const addPost = () => {
      if (title.value === "") {
        $common.Error("标题不能为空");
        return;
      } else if (content.value === "") {
        $common.Error("内容不能为空");
        return;
      } else {
        $.ajax({
          url: $constant.baseUrl + "/user/profile/add/",
          type: "POST",
          headers: {
            Authorization: "Bearer " + store.state.user.token
          },
          data: {
            title: title.value,
            content: content.value
          },
          success(resp) {
            if (resp.error_message === "success") {
              router.push({
                name: "user_profile_index"
              });
            } else {
              $common.Error(resp.error_message);
            }
          },
          error(jqXHR) {
            $common.failedRequest(jqXHR);
          }
        });
      }
    };
    let policy = ref({});
    let file = ref(null);
    let url = ref('');
    const getPolicy = event => {
      // 先获得后端授权
      file.value = event.target.files[0];
      $.ajax({
        url: $constant.baseUrl + "/user/profile/upload/image/",
        type: "POST",
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        success(resp) {
          // 成功获取后端授权
          policy.value.key = `${resp.dir}/${Date.now()}-${file.value.name}`;
          policy.value.OSSAccessKeyId = resp.accessid;
          policy.value.policy = resp.policy;
          policy.value.signature = resp.signature;
          policy.value.file = file.value;
          policy.value.host = resp.host;
          policy.value.f = true;
        },
        error(jqXHR) {
          $common.failedRequest(jqXHR);
        }
      });
    };
    const upload = () => {
      if (!file.value || !policy.value.f) return;
      let formData = new FormData();
      formData.append('key', policy.value.key);
      formData.append('OSSAccessKeyId', policy.value.OSSAccessKeyId);
      formData.append('policy', policy.value.policy);
      formData.append('signature', policy.value.signature);
      formData.append('file', policy.value.file);
      $.ajax({
        url: policy.value.host,
        type: "POST",
        data: formData,
        processData: false,
        contentType: false,
        success() {
          url.value = `${policy.value.host}/${formData.get('key')}`;
        },
        error(jqXHR) {
          $common.failedRequest(jqXHR);
        }
      });
    };
    return {
      content,
      compiledContent,
      is_edit,
      changeEdit,
      title,
      textAreaRef,
      addPost,
      getPolicy,
      upload,
      url
    };
  }
};