import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import constant from './utils/constant'
import common from './utils/common'
import "../src/assets/css/font.css"

const app = createApp(App);

app.config.globalProperties.$constant = constant
app.config.globalProperties.$common = common

app.use(store).use(router).mount('#app')

// 下面代码解决el的button切换窗口大小引起的报错
const debounce = (fn, delay) => {
    let timer
    return (...args) => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fn(...args)
        }, delay)
    }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 200);
        super(callback);
    }
}

