import "core-js/modules/es.array.push.js";
import { ref, watchEffect, onMounted, computed, getCurrentInstance } from "vue";
import MarkdownIt from "markdown-it";
import mark from "markdown-it-mark";
import router from '@/router';
import Prism from "prismjs";
import $ from 'jquery';
export default {
  props: {
    initialHeight: {
      type: Number,
      default: 400
    },
    width: {
      type: String,
      default: '100%'
    },
    post: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const $constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    const expand = ref(false);
    const maxHeight = ref(props.initialHeight);
    const defaultHeight = ref(props.initialHeight);
    const collapseContent = ref();
    const post = ref(props.post); // 这里取出来只是在js里面操作，不能返回
    let isLike = ref();
    let favour = ref(props.post.favour);
    const f = ref(true);

    // 拉伸组件的代码
    watchEffect(() => {
      if (!collapseContent.value) {
        return;
      }
      maxHeight.value = collapseContent.value.scrollHeight;
    });
    const onExpand = () => {
      expand.value = !expand.value;
      if (!defaultHeight.value) {
        defaultHeight.value = collapseContent.value.clientHeight;
      }
    };
    onMounted(() => {
      if (collapseContent.value.clientHeight < defaultHeight.value) {
        f.value = false; // 隐藏展开文字
      }
    });

    //编译markdown的代码
    const md = new MarkdownIt({
      html: true,
      breaks: true,
      linkify: true,
      typographer: true,
      highlight: function (str, lang) {
        if (lang && Prism.languages[lang]) {
          try {
            return Prism.highlight(str, Prism.languages[lang], lang);
          } catch (err) {
            console.error(`代码高亮错误: ${err}`);
          }
        }
        return "";
      }
    }).use(mark);
    // 设置图片样式
    md.use(function (md) {
      const defaultRender = md.renderer.rules.image;
      let imgCnt = 0; // 初始化计数器
      md.renderer.rules.image = function (tokens, idx, options, env, self) {
        var result = defaultRender(tokens, idx, options, env, self);
        // 检查当前图片是否是第一个
        let firstStyle = '';
        if (imgCnt === 0) {
          firstStyle = 'margin-top: -55px; border-top: 1px dotted grey;';
        }
        imgCnt++;
        return `
                    <div class="responsive-image-container" style="${firstStyle} position: relative; width: 100%;padding-bottom: 35%; overflow: hidden;border-bottom: 1px dotted grey; margin-bottom: -70px;">
                        <img class="responsive-image" style="position: absolute; top: 0; left: 0; width: 100%; height: 95%; object-fit: contain;" ${result.split("<img ")[1]}
                    </div>
                `;
      };
    });
    let compiledContent = computed(() => {
      // 将markdown文件编译成html
      return md.render(post.value.content);
    });
    let time = ref($common.getDateDiff(post.value.createtime));

    // 点赞
    const addFavour = () => {
      if (localStorage.getItem("jwt_token") == null) {
        router.push({
          name: 'user_account_login'
        });
      } else {
        $.ajax({
          url: $constant.baseUrl + "/square/favour/",
          type: "get",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token")
          },
          data: {
            post_id: post.value.id
          },
          success(resp) {
            if (resp.error_message === "success") {
              if (resp.op === "like") {
                isLike.value = true;
                favour.value++;
              } else {
                isLike.value = false;
                favour.value--;
              }
            }
          },
          error() {
            console.log("连接失败");
          }
        });
      }
    };
    return {
      f,
      time,
      favour,
      expand,
      isLike,
      maxHeight,
      defaultHeight,
      collapseContent,
      compiledContent,
      onExpand,
      addFavour
    };
  }
};