import "core-js/modules/es.array.push.js";
import { reactive } from "vue";
import router from '@/router';
import { VAceEditor } from 'vue3-ace-editor';
import "ace-builds/webpack-resolver";
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/ext-language_tools';
export default {
  components: {
    VAceEditor
  },
  setup() {
    const go = name => {
      router.push({
        name: name
      });
    };
    const bot = `package com.kob.botrunningsystem.utils;

import java.io.File;
import java.io.FileNotFoundException;
import java.util.*;

public class Bot implements java.util.function.Supplier<Integer>{
    static class Cell {
        public int x, y;
        public Cell(int x, int y) {
            this.x = x;
            this.y = y;
        }
    }

    private boolean check_tail_increasing(int step) { // 检验当前回合蛇是否变长
        if(step <= 10) return true;
        return step % 3 == 1;
    }

    public List<Cell> getCells(int sx, int sy, String steps) { // 获得蛇的身体
        steps = steps.substring(1, steps.length() - 1);
        List<Cell> res = new ArrayList<>();

        int[] dx = {-1, 0, 1, 0}, dy = {0, 1, 0, -1};

        int x = sx, y = sy;
        int step = 0;
        res.add(new Cell(x, y));
        for(int i = 0; i < steps.length(); i ++) {
            int d = steps.charAt(i) - '0';
            x += dx[d];
            y += dy[d];
            res.add(new Cell(x, y));
            if(!check_tail_increasing(++ step)) { // 不变长砍掉尾巴
                res.remove(0);
            }
        }
        return res;
    }

    public int bfs(int sx, int sy, int no, int[][] g)
    {
        if(sx < 0 || sx >= 13 || sy < 0 || sy >= 14 || g[sx][sy] == 1) return 0;

        // string    x#y
        int cnt = 1;
        Queue<String> q = new LinkedList<>();
        q.offer(Integer.toString(sx) + '#' + Integer.toString(sy));
        boolean[][] st = new boolean[13][14];
        st[sx][sy] = true;

        int[] dx = {-1, 0, 1, 0}, dy = {0, 1, 0, -1};

        while(!q.isEmpty())
        {
            String t = q.poll(); // 取出并弹出队头
            int k = 0;
            for(int i = 0; i < t.length(); i ++)
                if(t.charAt(i) == '#')
                {
                    k = i;
                    break;
                }
            int x = Integer.parseInt(t.substring(0, k));
            int y = Integer.parseInt(t.substring(k + 1));

            for(int i = 0; i < 4; i ++)
            {
                if(i != no)
                {
                    int a = x + dx[i], b = y + dy[i];
                    if(a >= 0 && a < 13 && b >= 0 && b < 14 && !st[a][b] && g[a][b] == 0) {
                        cnt ++;
                        st[a][b] = true;
                        q.offer(Integer.toString(a) + '#' + Integer.toString(b));
                    }
                }
            }
        }
        return cnt;
    }

    public Integer nextMove(String input) {
        String[] strs = input.split("#");

        int[][] g = new int[13][14];
        for(int i = 0, k = 0; i < 13; i ++) {
            for(int j = 0; j < 14; j ++, k ++) {
                if(strs[0].charAt(k) == '1')
                    g[i][j] = 1;
            }
        }

        int aSx = Integer.parseInt(strs[1]), aSy = Integer.parseInt(strs[2]);
        int bSx = Integer.parseInt(strs[4]), bSy = Integer.parseInt(strs[5]);
        List<Cell> aCells = getCells(aSx, aSy, strs[3]);
        List<Cell> bCells = getCells(bSx, bSy, strs[6]);

        for(Cell c : aCells) g[c.x][c.y] = 1;
        for(Cell c : bCells) g[c.x][c.y] = 1;

        int[] dx = {-1, 0, 1, 0}, dy = {0, 1, 0, -1};
        int sx = aCells.get(aCells.size() - 1).x;
        int sy = aCells.get(aCells.size() - 1).y;

        int up = bfs(sx - 1, sy, 2, g);
        int right = bfs(sx, sy + 1, 3, g);
        int down = bfs(sx + 1, sy, 0, g);
        int left = bfs(sx, sy - 1, 1, g);
        if(up >= down && up >= left && up >= right) return 0;
        if(right >= up && right >= down && right >= left) return 1;
        if(down >= up && down >= right && down >= left) return 2;

        return 3;
    }

    @Override
    public Integer get() {
        File file = new File("input.txt");
        try {
            Scanner sc = new Scanner(file);
            return nextMove(sc.next());
        } catch (FileNotFoundException e) {
            throw new RuntimeException(e);
        }
    }
}`;
    const aceConfig = reactive({
      theme: 'crimson_editor',
      //主题:clouds、crimson_editor
      arr: [/*所有主题*/
      "ambiance", "chaos", "chrome", "clouds", "clouds_midnight", "cobalt", "crimson_editor", "dawn", "dracula", "dreamweaver", "eclipse", "github", "gob", "gruvbox", "idle_fingers", "iplastic", "katzenmilch", "kr_theme", "kuroir", "merbivore", "merbivore_soft", "monokai", "mono_industrial", "pastel_on_dark", "solarized_dark", "solarized_light", "sqlserver", "terminal", "textmate", "tomorrow", "tomorrow_night", "tomorrow_night_blue", "tomorrow_night_bright", "tomorrow_night_eighties", "twilight", "vibrant_ink", "xcode"],
      options: {
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        tabSize: 2,
        showPrintMargin: false,
        fontSize: 20
      }
    });
    const dataForm = reactive({
      textareashow: '{"A":"A1"}'
    });
    const editorInit = () => {
      try {
        dataForm.textareashow = JSON.stringify(JSON.parse(dataForm.textareashow), null, 2);
      } catch (e) {
        console.log(`JSON字符串错误：${e.message}`);
      }
    };
    return {
      go,
      aceConfig,
      editorInit,
      bot
    };
  }
};