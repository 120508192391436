import "core-js/modules/es.array.push.js";
import { useRoute } from 'vue-router';
import { computed, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import router from '../../router/index';
export default {
  setup() {
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    const route = useRoute();
    const store = useStore();
    let route_name = computed(() => {
      return route.name;
    });
    const logout = () => {
      $common.confirmAction("警告", "确定退出登录状态吗?", "red").then(result => {
        if (result) {
          closeNavbar();
          store.dispatch("logout");
          router.push({
            name: "user_account_login"
          });
        }
      });
    };
    const closeNavbar = () => {
      const navbarToggler = document.querySelector('.navbar-toggler');
      const navbarCollapse = document.getElementById('navbarText');
      if (navbarCollapse.classList.contains('show')) {
        navbarToggler.click();
      }
    };
    return {
      route_name,
      logout,
      closeNavbar
    };
  }
};