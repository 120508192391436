// 存下需要修改的post
export default {
    state: {
        id: "",
        title: "",
        authorId: "",
        authorName: "",
        content: "",
        favour: "",
        createtime: "",
        modifytime: "",
    },

    getters: {
    },

    mutations: {
        updatePost(state, post) {
            state.id = post.id;
            state.title = post.title;
            state.authorId = post.authorId;
            state.authorName = post.authorName;
            state.content = post.content;
            state.favour = post.favour;
            state.createtime = post.createtime;
            state.modifytime = post.modifytime;
        },
    },

    actions: {
        updateLocalStorage(state, post) {
            localStorage.setItem("post_id", post.id);
            localStorage.setItem("post_title", post.title);
            localStorage.setItem("post_authorId", post.authorId);
            localStorage.setItem("post_authorName", post.authorName);
            localStorage.setItem("post_content", post.content);
            localStorage.setItem("post_favour", post.favour);
            localStorage.setItem("post_createtime", post.createtime);
            localStorage.setItem("post_modifytime", post.modifytime);
        }
    },

    modules: {
    }
}