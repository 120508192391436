import "core-js/modules/es.array.push.js";
import { useStore } from 'vuex';
import { ref, getCurrentInstance } from 'vue';
import router from '../../../router/index';
import $ from "jquery";
export default {
  name: "RanklistIndexView",
  setup() {
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    const store = useStore();
    let username = ref('');
    let password = ref('');
    const jwt_token = localStorage.getItem("jwt_token");
    if (jwt_token) {
      // 每次跳转到登录页面前看看本地有没有token，并验证过期了没有
      store.commit("updateToken", jwt_token);
      store.dispatch("getInfo", {
        success() {
          store.commit("updatePullingInfo", false);
          router.push({
            name: "home"
          });
        },
        error() {
          store.commit("updatePullingInfo", false);
        }
      });
    } else {
      store.commit("updatePullingInfo", false);
    }
    const login = () => {
      if ($common.isEmpty(username.value) || $common.isEmpty(password.value)) {
        $common.Error("用户名或密码为空");
        return;
      }
      store.dispatch("login", {
        username: username.value,
        password: password.value,
        success() {
          store.dispatch("getInfo", {
            // 登陆成功后先获取用户信息再跳转到首页
            success() {
              router.push({
                name: "home"
              });
            }
          });
        },
        error(resp) {
          $common.Error(resp);
        }
      });
    };
    const go = Name => {
      router.push({
        name: Name
      });
    };
    const qq_login = () => {
      $.ajax({
        url: "https://www.hrf-w.com/api/user/account/qq/apply_code/",
        type: "GET",
        success: resp => {
          if (resp.error_message === "success") {
            window.location.replace(resp.apply_code_url);
          }
        }
      });
    };
    return {
      username,
      password,
      login,
      go,
      qq_login
    };
  }
};