import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import NavBar from './components/utils/NavBar';
import FootView from './components/utils/FootView';
export default {
  name: 'App',
  components: {
    NavBar,
    FootView
  },
  setup() {}
};