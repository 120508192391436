import "core-js/modules/es.array.push.js";
import { ref, onMounted, nextTick, watch, computed, getCurrentInstance } from 'vue';
import MarkdownIt from "markdown-it";
import Prism from "prismjs";
import "prismjs/themes/prism-coy.css";
import mark from "markdown-it-mark";
import { useStore } from 'vuex';
import $ from "jquery";
import router from "@/router";
export default {
  setup() {
    const $constant = getCurrentInstance().appContext.config.globalProperties.$constant;
    const $common = getCurrentInstance().appContext.config.globalProperties.$common;
    const store = useStore();
    let is_edit = ref(true);
    const textAreaRef = ref(null);
    let post = ref({});
    let error_message = ref("");
    if (store.state.post.id !== "") {
      post.value.id = store.state.post.id;
      post.value.title = store.state.post.title;
      post.value.content = store.state.post.content;
      post.value.favour = store.state.post.favour;
      post.value.createtime = store.state.post.createtime;
    } else {
      post.value.id = localStorage.getItem("post_id");
      post.value.title = localStorage.getItem("post_title");
      post.value.content = localStorage.getItem("post_content");
      post.value.favour = localStorage.getItem("post_content");
      post.value.createtime = localStorage.getItem("post_createtime");
    }

    // ------------------------------------------------------------------------
    // 初始化markdown的配置，markdown转化时会用到
    const md = new MarkdownIt({
      html: true,
      breaks: true,
      linkify: true,
      typographer: true,
      highlight: function (str, lang) {
        if (lang && Prism.languages[lang]) {
          try {
            return Prism.highlight(str, Prism.languages[lang], lang);
          } catch (err) {
            console.error(`代码高亮错误: ${err}`);
          }
        }
        return "";
      }
    }).use(mark);
    md.use(function (md) {
      const defaultRender = md.renderer.rules.image;
      let imgCnt = 0; // 初始化计数器
      md.renderer.rules.image = function (tokens, idx, options, env, self) {
        var result = defaultRender(tokens, idx, options, env, self);
        // 检查当前图片是否是第一个
        let firstStyle = '';
        if (imgCnt === 0) {
          firstStyle = 'margin-top: -60px; border-top: 1px dotted grey;';
        }
        imgCnt++;
        return `
                    <div class="responsive-image-container" style="${firstStyle} position: relative; width: 100%;padding-bottom: 35%; overflow: hidden;border-bottom: 1px dotted grey;">
                        <img class="responsive-image" style="position: absolute; top: 0; left: 0; width: 100%; height: 95%; object-fit: contain;" ${result.split("<img ")[1]}
                    </div>
                `;
      };
    });
    const compiledContent = computed(() => {
      // 将markdown文件编译成html
      return md.render(post.value.content);
    });
    const changeEdit = () => {
      is_edit.value = !is_edit.value;
    };
    watch(is_edit, async newVal => {
      if (newVal) {
        // 使用定时器或者 nextTick 是为了等待 textarea 渲染完毕后，再进行调整
        await nextTick();
        autoResize(textAreaRef.value);
      }
    });

    // 自动调整textarea的高度
    onMounted(async () => {
      await nextTick();
      autoResize(textAreaRef.value);
    });
    watch(textAreaRef, textArea => {
      if (textArea) {
        textArea.addEventListener("input", () => autoResize(textArea));
      }
    });
    function autoResize(textArea) {
      textArea.style.height = textArea.scrollHeight + 'px';
    }
    // ---------------------------------------------------------------------------------

    const updatePost = () => {
      error_message.value = "";
      if (post.value.title === "") {
        error_message.value = "标题不能为空";
        return;
      } else if (post.value.content === "") {
        error_message.value = "内容不能为空";
        return;
      } else if (post.value.id === -1) {
        error_message.value = "post不存在";
      } else {
        $common.confirmAction("确认保存", "你确定已经修改完毕?", "#F47100").then(result => {
          if (result) {
            $.ajax({
              url: $constant.baseUrl + "/user/profile/update/",
              type: "POST",
              data: {
                post_id: post.value.id,
                title: post.value.title,
                content: post.value.content
              },
              headers: {
                Authorization: "Bearer " + store.state.user.token
              },
              success(resp) {
                if (resp.error_message === "success") {
                  router.push({
                    name: "user_profile_index"
                  });
                } else {
                  error_message.value = resp.error_message;
                }
              },
              error(jqXHR) {
                $common.failedRequest(jqXHR);
              }
            });
          }
        });
      }
    };
    let policy = ref({});
    let file = ref(null);
    let url = ref('');
    const getPolicy = event => {
      // 先获得后端授权
      file.value = event.target.files[0];
      $.ajax({
        url: $constant.baseUrl + "/user/profile/upload/image/",
        type: "POST",
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        success(resp) {
          // 成功获取后端授权
          policy.value.key = `${resp.dir}/${Date.now()}-${file.value.name}`;
          policy.value.OSSAccessKeyId = resp.accessid;
          policy.value.policy = resp.policy;
          policy.value.signature = resp.signature;
          policy.value.file = file.value;
          policy.value.host = resp.host;
          policy.value.f = true;
        },
        error(jqXHR) {
          $common.failedRequest(jqXHR);
        }
      });
    };
    const upload = () => {
      if (!file.value || !policy.value.f) return;
      let formData = new FormData();
      formData.append('key', policy.value.key);
      formData.append('OSSAccessKeyId', policy.value.OSSAccessKeyId);
      formData.append('policy', policy.value.policy);
      formData.append('signature', policy.value.signature);
      formData.append('file', policy.value.file);
      $.ajax({
        url: policy.value.host,
        type: "POST",
        data: formData,
        processData: false,
        contentType: false,
        success() {
          url.value = `${policy.value.host}/${formData.get('key')}`;
        },
        error(jqXHR) {
          $common.failedRequest(jqXHR);
        }
      });
    };
    return {
      compiledContent,
      is_edit,
      changeEdit,
      textAreaRef,
      post,
      updatePost,
      error_message,
      getPolicy,
      upload,
      url
    };
  }
};