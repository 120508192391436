import { ref } from "vue";
import { useStore } from 'vuex';
import MonitorSystem from "@/components/admin/MonitorSystem";
import UserSystem from "@/components/admin/UserSystem.vue";
export default {
  components: {
    MonitorSystem,
    UserSystem
  },
  setup() {
    const store = useStore();
    store.commit("openAdmin");
    let select = ref(0);
    let captions = ["系统首页", "监控系统", "用户管理", "网站设置", "系统公告", "文章管理", "流量监控", "资源管理", "留言管理", "数据库模块"];
    const changeSelect = index => {
      select.value = index;
    };
    return {
      select,
      captions,
      changeSelect
    };
  }
};